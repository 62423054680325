import React, { useEffect, useState } from "react";
import { Grid } from "semantic-ui-react";
import { useParams } from "react-router-dom";
import createNotification from "../../utils/notification";
import {
  getProductList,
  getSectorName,
  getCategoryName,
  getSubcategoryName,
} from "../../providers/productProvider";
import ProductListPage from "../../components/ProductListPage";
import ProductListFilters from "../../components/ProductListFilters";

import "./styles.css";

export default function ProductList() {
  const params = useParams();
  const [products, setProducts] = useState([]);
  const [name, setName] = useState("");
  const [orderBy, setOrderBy] = useState("-created_at");
  const [visible, setVisible] = useState(true);
  const [itemsPerRow, setItemsPerRow] = useState(3);
  const [numberOfPages, setNumberOfPages] = useState(1);
  const [selectedPage, setSelectedPage] = useState(1);
  const [searchName, setSearchName] = useState("");
  const [filtersSelections, setFiltersSelections] = useState(null);

  async function getNameData(sectorId, categoryId, subcategoryId) {
    if (!sectorId) return;

    let response = null;
    if (subcategoryId) {
      response = await getSubcategoryName(subcategoryId);
    } else if (categoryId) {
      response = await getCategoryName(categoryId);
    } else {
      response = await getSectorName(sectorId);
    }

    if (response.error) {
      setName("Nome indefinido");
    }

    return setName(response);
  }

  function cleanFilters() {
    setSearchName("");

    let tempFiltersSelections = {};
    Object.keys(filtersSelections).map((key) => {
      tempFiltersSelections[key] = "Todos";
    });

    setFiltersSelections(tempFiltersSelections);
  }

  async function getProductListData(
    sectorId,
    categoryId,
    subcategoryId,
    filters,
    page = 1
  ) {
    const response = await getProductList(
      sectorId,
      categoryId,
      subcategoryId,
      filters,
      orderBy,
      page,
      searchName
    );

    if (response.error) {
      return createNotification(
        "error",
        "Erro",
        "Ocorreu um erro ao carregar a lista de produtos"
      );
    }

    const tempNumberOfPages = Math.floor(response.total_products / 20);
    setNumberOfPages(tempNumberOfPages + 1);
    return setProducts(response.page);
  }

  useEffect(() => {
    getNameData(params.sectionId, params.categoryId, params.subcategoryId);
  }, [params]);

  useEffect(() => {
    getProductListData(
      params.sectionId,
      params.categoryId,
      params.subcategoryId,
      filtersSelections,
      selectedPage
    );
  }, [params, filtersSelections, orderBy, selectedPage, searchName]);

  useEffect(() => {
    setVisible(!visible);
  }, [itemsPerRow]);

  return (
    <Grid columns={2} className="product-list-main-container" stackable>
      <Grid.Row>
        <Grid.Column width={5}>
          <ProductListFilters
            name={name}
            sector={params.sectionId}
            category={params.categoryId}
            subcategory={params.subcategoryId}
            filtersSelections={filtersSelections}
            setFiltersSelections={setFiltersSelections}
            searchName={searchName}
            setSearchName={setSearchName}
            cleanFilters={cleanFilters}
          />
        </Grid.Column>
        <Grid.Column width={11}>
          <ProductListPage
            setOrderBy={setOrderBy}
            setItemsPerRow={setItemsPerRow}
            itemsPerRow={itemsPerRow}
            products={products}
            selectedPage={selectedPage}
            numberOfPages={numberOfPages}
            setSelectedPage={setSelectedPage}
          />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
}
