import provider from "./config";
import { formatDateYearMonthDay } from '../utils/validateForm';

const slugify = (text) => {
  return text
    .toString()                   // Cast to string (optional)
    .normalize('NFKD')            // The normalize() using NFKD method returns the Unicode Normalization Form of a given string.
    .toLowerCase()                // Convert the string to lowercase letters
    .trim()                       // Remove whitespace from both sides of a string (optional)
    .replace(/\s+/g, '-')         // Replace spaces with -
    .replace(/[^\w\-]+/g, '')     // Remove all non-word chars
    .replace(/\_/g,'-')           // Replace _ with -
    .replace(/\-\-+/g, '-')       // Replace multiple - with single -
    .replace(/\-$/g, '');         // Remove trailing -
}

export async function getSectors() {
  try {
    const response = await provider.get("/classes", {
      params: {
        id: '61cd2732ad47e0a02eab67e3',
      }
    });

    response.data.shift();

    const classes = response.data;
    const sectors = [];

    classes.forEach((classe) => {
      let data = {
        id: classe.id,
        name: classe.nome,
        slug: slugify(classe.nome),
        position: null,
        icon: null,
      };

      let found = false;
      for (const sector of sectors) {
        if (classe.idPai === sector.id) {
          found = true;
          data = {
            ...data,
            sector: sector.id,
            sector_name: sector.name,
            subcategories: [],
          }
          if (Array.isArray(sector.categories)) {
            sector.categories.push(data);
            sector.categories_count += 1;
          } else {
            sector.categories = [data];
            sector.categories_count = 1;
          }
        } else {
          if (sector.categories) {
            for (const category of sector.categories) {
              if (classe.idPai === category.id) {
                found = true;
                data = {
                  ...data,
                  sector: sector.id,
                  category: category.id,
                  category_name: category.name,
                }
                if (Array.isArray(category.subcategories)) {
                  category.subcategories.push(data);
                  category.subcategories_count += 1;
                } else {
                  category.subcategories = [data];
                  category.subcategories_count = 1;
                }
              }
            }
          }
        }
      }

      if (found === false) {
        sectors.push(data);
      }
    });

    return sectors;
  } catch (err) {
    return { error: true, message: err.message };
  }
}

export async function getSectorName(id) {
  try {
    const response = await provider.get(`/classes/${id}`);
    const { nome } = response.data;
    return nome;
  } catch (err) {
    return { error: true, message: err.message };
  }
}

export async function getCategoryName(id) {
  try {
    const response = await provider.get(`/classes/${id}`);
    const { nome } = response.data;
    return nome;
  } catch (err) {
    return { error: true, message: err.message };
  }
}

export async function getSubcategoryName(id) {
  try {
    const response = await provider.get(`/classes/${id}`);
    const { nome } = response.data;
    return nome;
  } catch (err) {
    return { error: true, message: err.message };
  }
}

export async function getProduct(id) {
  try {
    const response = await provider.get(`recursos/${id}`);

    const base = response.data;

    const product = [{
      "id": base.id,
      "stock": 10,
      "name": base.nome,
      "product_base": {
        "id": base.id,
        "in_stock": true,
        "is_active": base.situacao === 'Ativo',
        "highlight": base.destaque,
        "name": base.produtoBase,
        "description": base.observacao,
        "code": { "id": base.id, "uuid": base.id },
        "total_evaluation": 0,
        "average_grade": "0.0",
        "image": base.foto,
        "image_small": base.foto,
        "image_medium": base.foto
      },
      "product_type": base.Classe.id,
      "value": base.prVenda,
      "promotional_value": null,
      "weight": base.pesoLiquido,
      "sector": [],
      "category": [],
      "subcategory": [],
      "free_shipping": false,
      "in_stock": true,
      "is_active": base.situacao === 'Ativo',
      "installment": "10",
      "created_at": base.criadoEm,
      "album": [
        {
          "id": 1,
          "product": base.id,
          "image": base.foto,
          "image_small": base.foto,
          "image_medium": base.foto,
          "created_at": base.criadoEm
        },
      ],
      "list_properties": [
        // {
        //   "id": 118,
        //   "feature": {
        //     "id": 10,
        //     "title": "Modelo",
        //     "product_type": {
        //       "id": 3,
        //       "title": "Acessórios Carro",
        //       "sector": null
        //     }
        //   },
        //   "product": 75,
        //   "option": { "id": 28, "title": "Offroad", "feature": 10 }
        // }
      ]
    }];

    return product;
  } catch (err) {
    return { error: true, message: err.message };
  }
}

export async function getProductVariation(id) {
  try {
    const response = await provider.get(
      `/product/${id}`
    );
    return response.data;
  } catch (err) {
    return { error: true, message: err.message };
  }
}

export async function getProductList(
  sector = null,
  category = null,
  subcategory = null,
  filters = null,
  orderBy = "-created_at",
  page = 1,
  search = "",
  highlight = false
) {
  let url = `/recursos?page=${page}&nome=${search}&situacao=Ativo`;

  if (highlight) url += "&destaque=true";

  // if (filters) {
  //   let hasFilters = false;
  //   const filtersValues = [];

  //   Object.keys(filters).map((key) => {
  //     if (filters[key] !== "Todos") {
  //       hasFilters = true;
  //       filtersValues.push(filters[key]);
  //     }
  //   });

  //   if (hasFilters) {
  //     url += "&attrs=";

  //     filtersValues.map((filter, index) => {
  //       if (index !== filtersValues.length - 1) {
  //         url += `${filter},`;
  //       } else {
  //         url += filter;
  //       }
  //     });
  //   }
  // }

  try {
    const response = await provider.get(url);

    let page = [
      ...response.data.docs,
    ];
    const total_products = response.data.total;

    page = page.map((product) => {
      return {
        "product_base": {
          "id": product.id,
          "in_stock": true,
          "highlight": product.destaque,
          "is_active": product.situacao === 'Ativo',
          "product": product.nome,
          "code": product.id,
          "description": product.observacao,
          "image": product.foto,
          "image_small": product.foto,
          "image_medium": product.foto
        },
        "id": product.id,
        "value": product.prVenda,
        "promotional_value": null,
        "in_stock": true,
        "is_active": product.situacao === 'Ativo',
        "free_shipping": false,
        "installment": "10",
        "created_at": product.criadoEm,
      };
    })

    return { page, total_products };
  } catch (err) {
    return { error: true, message: err.message };
  }
}

export async function getFilters(
  sector = 1,
  category = null,
  subcategory = null
) {
  let url = `/property/filter?sector=${sector}`;

  if (category) {
    url += `&category=${category}`;
  }

  if (subcategory) {
    url += `&subcategory=${subcategory}`;
  }

  try {
    const response = await provider.get(url);
    return response.data;
  } catch (err) {
    return { error: true, message: err.message };
  }
}

export async function evaluateProduct(payload) {
  try {
    const response = await provider.post("/tabela/", payload);
    return response.data;
  } catch (err) {
    return { error: true, message: err.message };
  }
}

export async function getEvaluations(id, page = 1) {
  try {
    const response = await provider.get(
      `/tabela?classe=Avalia%C3%A7%C3%B5es&recurso=${id}&page=${page}`
    );

    const data = {
      "count": response.data.total,
      "next": null,
      "previous": null,
      "results": response.data.docs.map((doc) => {
        return {
          "id": doc.id,
          "name": doc.nome,
          "email": doc.codigo,
          "evaluated": doc.idRecurso,
          "grade": doc.quantidade,
          "commentary": doc.observacao,
          "published": formatDateYearMonthDay(doc.criadoEm),
        };
      }),
    }
    return data;
  } catch (err) {
    return { error: true, message: err.message };
  }
}
