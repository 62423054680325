/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import {
  // Grid,
  // Image,
  Segment,
  Card,
  // Tab,
  // List,
  // Item,
  // Header,
  Divider,
} from "semantic-ui-react";
import { motion } from "framer-motion";
// import { FaShippingFast, FaCreditCard, FaClock } from "react-icons/fa";
// import { MdMoneyOff } from "react-icons/md";
import stagger from "../../animations/stagger";
// import { data, highlightData } from "../../utils/data";
import { getProductList } from "../../providers/productProvider";
import createNotification from "../../utils/notification";
import { useParams, useHistory } from "react-router-dom";
import HomeCarousel from "../../components/HomeCarousel";
import ProductCard from "../../components/ProductCard";
// import HighlightCard from "../../components/HighlightCard";
import * as generalProvider from "../../providers/GeneralProvider";
// import { getSectors } from "../../providers/productProvider";
// import provider from "../../providers/config";

import {useAuth} from "../../contexts/auth";

import "./styles.css";

const NewsContainer = ({ products }) => {
  // <Card.Group
  //   as={motion.div}
  //   variants={stagger}
  //   animate="animate"
  //   itemsPerRow={4}
  //   stackable={false}
  // >
  // </Card.Group>
  return (
    <div className="horizontal-list">
      {products.map((product) => (
        <ProductCard key={product.product_base.id} product={product} />
      ))}
    </div>
  );
};

const HomepageLayout = () => {
  const params = useParams();
  const history = useHistory();
  const {from, setFrom} = useAuth();

  const [products, setProducts] = useState([]);
  const [banners, setBanners] = useState([]);

  useEffect(() => {
    if(from){
      setFrom(null);
      if(from === "pagamento"){
        return history.push("/pagamento");
      }
      else if(from === "produto"){
        return history.push("/carrinho");
      }
    }
  }, [from]);

  useEffect(() => {
    async function getProductListData(orderBy = "-created_at") {
      const response = await getProductList(null, null, null, null, orderBy, 1, "", true);

      if (response.error) {
        return createNotification(
          "error",
          "Erro",
          "Ocorreu um erro ao carregar a lista de produtos"
        );
      }
      
      return setProducts(response.page);
    }
    getBanners();
    getProductListData();
  }, [params.id]);

  async function getBanners() {
    const response = await generalProvider.getBanners();
    
    return setBanners(response);
  }

  return (
    <>
      {banners.length > 0 && <HomeCarousel banners={banners} />}
      <Divider style={{ margin: 0 }} />
      {/* <div className="support-container">
        <section>
          <FaShippingFast size={58} color={process.env.REACT_APP_ICON_COLOR} />
          <div className="content">
            <strong>Entregas grátis</strong>
            <p>Entregas grátis para todo o país</p>
          </div>
        </section>
        <section>
          <FaClock size={58} color={process.env.REACT_APP_ICON_COLOR} />
          <div className="content">
            <strong>Suporte 24h</strong>
            <p>Suporte online 24 horas</p>
          </div>
        </section>
        <section>
          <MdMoneyOff size={58} color={process.env.REACT_APP_ICON_COLOR} />
          <div className="content">
            <strong>Promoções Imperdíveis</strong>
            <p>Promoções imperdíveis para todos os clientes</p>
          </div>
        </section>
        <section>
          <FaCreditCard size={58} color={process.env.REACT_APP_ICON_COLOR} />
          <div className="content">
            <strong>Parcelamento em até 10x</strong>
            <p>Parcelamentos em até 10x sem juros</p>
          </div>
        </section>
      </div> */}
      <div className="card-section-container">
        <strong>Destaques</strong>
        <Segment className="main-container" vertical>
          <NewsContainer products={products} />
        </Segment>
      </div>
      {/* <Divider /> */}
      {/* <div className="card-section-container">
        <strong>Promoções</strong>
        <Segment className="main-container" vertical>
          <NewsContainer products={products} />
        </Segment>
      </div>
      <Divider />
      <div className="card-section-container">
        <strong>Têndencias</strong>
        <Segment className="main-container" vertical>
          <NewsContainer products={products} />
        </Segment>
      </div> */}
      {/* <Segment style={{ padding: "2em 4em" }} vertical>
        <Grid columns={3} divided verticalAlign="middle" stackable>
          {highlightData.map((highlightCard) => (
            <HighlightCard key={highlightCard.id} card={highlightCard} />
          ))}
        </Grid>
      </Segment> */}
    </>
  );
};

export default HomepageLayout;
