import provider from "./config";

export async function calcularFrete(args) {
  try {
    let url = "/shipping?";
    Object.keys(args).map((key, index) => (url += `${key}=${args[key]}&`));
    const response = await provider.get(url);
    return response.data;
  } catch (err) {
    if (err.response.data.mensagem)
      return { error: true, message: err.response.data.mensagem };

    return {
      error: true,
      message:
        "Ocorreu um erro ao tentar buscar o preço de frete. Tente novamente",
    };
  }
}

export async function getAddress(postal_code){
  try {
    const response = await provider.get("address", {
      params: {
        postal_code
      }
    });
    return response.data;
  } catch (err) {
    if (err.response && err.response.data && err.response.data.mensagem)
      return { error: true, message: err.response.data.mensagem };

    return {
      error: true,
      message:
        "Endereço não encontrado",
    };
  }
}