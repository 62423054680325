import React, { useState, useEffect } from "react";
import { Grid, Segment, Divider, Button, Message } from "semantic-ui-react";
import {useHistory, Redirect} from "react-router-dom";
import CheckoutStep from "../../components/CheckoutStep";
import CheckoutAddress from "../../components/CheckoutAddress";
import CheckoutPayment from "../../components/CheckoutPayment";
import CheckContainer from "../../components/CheckContainer";
import CheckoutChoosePayment from "../../components/CheckoutChoosePayment";
import createNotification from "../../utils/notification";
import { calcularFrete } from "../../providers/correiosProvider";
import { checkout, boletoCheckout } from "../../providers/checkoutProvider";
import { check as checkCoupon } from "../../providers/couponProvider";

import { useAuth } from "../../contexts/auth";
import { useCart } from "../../contexts/cart";
import { useStore } from "../../contexts/store";

import "./styles.css";
import { PIXEL_TYPE_CHOICES, TrackPixelEvent } from "../../utils/facebookPixel";

function getSum(total, num) {
  return total + num;
}

export default function Checkout() {
  const { user } = useAuth();
  const { items, clear: clearCart } = useCart();
  const { store, pixelReady } = useStore();
  const history = useHistory();

  const [coupon, setCoupon] = useState("");
  const [couponValue, setCouponValue] = useState(null);
  const [loadingCoupon, setLoadingCoupon] = useState(false);
  const [couponPrice, setCouponPrice] = useState("0");
  const [totalPrice, setTotalPrice] = useState("0");
  const [totalAmount, setTotalAmount] = useState("0");

  const [selectSavedAddress, setSelectSaveAddress] = useState(false);
  const [selectedAddress, setSelectedAddress] = useState(null);
  const [cardInfo, setCardInfo] = useState(null);
  const [activeComponent, setActiveComponent] = useState(0);
  const [loadingCheckout, setLoadingCheckout] = useState(false);
  const [loadingCalc, setLoadingCalc] = useState(false);
  const [sedexShipping, setSedexShipping] = useState(null);
  const [pacShipping, setPacShipping] = useState(null);
  const [paymentType, setPaymentType] = useState(null);
  const [selectedShipping, setSelectedShipping] = useState({
    service: null,
    value: null,
  });

  useEffect(() => {
    let tempPrice = items
                    .map((item) => item.price * item.count)
                    .reduce(getSum, 0)
                    .toFixed(2);
    setTotalPrice(tempPrice);
  }, [items]);

  useEffect(() => {
    generateTotalPrice();
  }, [totalPrice, couponValue, couponPrice, selectedShipping]);

  useEffect(() => {
    if (selectedAddress !== null) {
      handleCalcShipping();
    } else {
      setSelectedShipping({
        service: null,
        value: null,
      });
      setSedexShipping(null);
      setPacShipping(null);
    }
  }, [selectedAddress]);

  async function handleCalcShipping() {
    setLoadingCalc(true);

    const weightList = items.map((item) => {
      if(!item.freeShipping){
        return (parseFloat(item.weight) * item.count);
      }
    });

    console.log(weightList);

    const reducer = (accumulator, currentValue) =>
      parseFloat(accumulator) + currentValue;

    console.log(String(weightList.reduce(reducer)));

    const args = {
      origem: store.address_postcode,
      destino: selectedAddress.address_postcode,
      peso: String(weightList.reduce(reducer)),
      formato: 1,
      comprimento: 15,
      altura: 1,
      largura: 10,
      diametro: 0,
    };

    const response = await calcularFrete(args);

    if (response.error === true) {
      setLoadingCalc(false);

      if(response.message === "Não foi encontrada precificação. ERP-036: Peso/Valor excede o limite de aceitacao do servico no ambito estadual (50)(-1)."){
        return createNotification("warning", "Erro", "A entrega por correios está desabilitada para esta compra por conta do peso dos itens.");
      }

      return createNotification("warning", "Erro", "A entrega por correios está desabilitada para estes itens.");
    }
    setSedexShipping(response.sedex);
    setPacShipping(response.pac);
    setLoadingCalc(false);
  }

  function handleSelectShipping(service) {
    switch (service) {
      default:
        break;
      case "sedex":
        return setSelectedShipping({
          service: "sedex",
          value: sedexShipping.Valor,
        });
      case "pac":
        return setSelectedShipping({
          service: "pac",
          value: pacShipping.Valor,
        });
      case "store":
        return setSelectedShipping({
          service: "store",
          value: 0,
        });
      case "freeShipping":
        return setSelectedShipping({
          service: "freeShipping",
          value: 0,
        });
    }
  }

  function handleStepComponent() {
    switch (activeComponent) {
      default:
        return;
      case 0:
        return (
          <CheckoutAddress
            disabled={loadingCalc}
            checked={selectSavedAddress}
            setChecked={setSelectSaveAddress}
            saveAddress={setSelectedAddress}
          />
        );
      case 1:
        return (
          <CheckoutChoosePayment
            total={totalAmount}
            installments={6}
            onChange={setPaymentType}
            isFreeShipping={selectedShipping.service === "freeShipping"}
          />
        );
      case 2:
        return (
          <CheckoutPayment
            total={totalAmount}
            installments={6}
            setCardInfo={setCardInfo}
            isFreeShipping={selectedShipping.service === "freeShipping"}
          />
        );
      case 2:
        return (
          <Message
            icon="shipping fast"
            header="Compra realizada com sucesso!"
            content={`A ${store.name} agradece sua preferência. Você pode acompanhar o seu pedido através do seu perfil.`}
          />
        );
    }
  }

  async function handlePayment() {
    setLoadingCheckout(true);
    const total_amount = totalAmount;

    const shipping_address =
      selectedShipping.service === "store"
        ? {
            recipient: selectedAddress.address_to,
            street: store.address_street,
            city: store.address_city,
            state: store.address_state,
            postcode: store.address_postcode,
            number: store.address_number,
            district: store.address_district,
            complement: store.address_complement,
          }
        : {
            recipient: selectedAddress.address_to,
            street: selectedAddress.address_street,
            city: selectedAddress.address_city,
            state: selectedAddress.address_state,
            postcode: selectedAddress.address_postcode,
            number: selectedAddress.address_number,
            district: selectedAddress.address_district,
            complement: selectedAddress.address_complement,
          };

    const cart = items.map((item) => {
      return { id: item.id, quantity: item.count };
    });

    let payload = {
      user: user.id,
      total_amount: parseFloat(total_amount),
      shipping_amount: parseFloat(selectedShipping.value),
      description: "pagamento",
      installment_number: cardInfo.installments,
      delivery_type:
        selectedShipping.service === "store"
          ? "RETIRAR NA LOJA" : selectedShipping.service === "freeShipping" ? "ENTREGA GRÁTIS"
          : selectedShipping.service.toUpperCase(),
      holder_name: cardInfo.holderName,
      exp_month: cardInfo.expMonth,
      exp_year: cardInfo.expYear,
      number: cardInfo.number,
      ccv: cardInfo.ccv,
      shipping_address,
      cart,
    };

    if(couponValue){
      payload = {...payload, coupon: couponValue.code};
    }

    const result = await checkout(payload, user.token);

    if (result.error) {
      setLoadingCheckout(false);
      return createNotification("error", "Erro", result.message);
    }

    setLoadingCheckout(false);
    setActiveComponent(3);
    clearCart();
    createNotification("success", "Sucesso", "Seu pedido foi realizado");
    
    if(pixelReady) {
      TrackPixelEvent(store.events, PIXEL_TYPE_CHOICES.Purchase);
    }
    
    return history.push("/perfil/meus-pedidos");
  }

  async function handleBoletoPayment() {
    setLoadingCheckout(true);
    const total_amount = totalAmount;

    const shipping_address =
      selectedShipping.service === "store"
        ? {
            recipient: selectedAddress.address_to,
            street: store.address_street,
            city: store.address_city,
            state: store.address_state,
            postcode: store.address_postcode,
            number: store.address_number,
            district: store.address_district,
            complement: store.address_complement,
          }
        : {
            recipient: selectedAddress.address_to,
            street: selectedAddress.address_street,
            city: selectedAddress.address_city,
            state: selectedAddress.address_state,
            postcode: selectedAddress.address_postcode,
            number: selectedAddress.address_number,
            district: selectedAddress.address_district,
            complement: selectedAddress.address_complement,
          };

    const cart = items.map((item) => {
      return { id: item.id, quantity: item.count };
    });

    let payload = {
      user: user.id,
      total_amount: parseFloat(total_amount),
      shipping_amount: parseFloat(selectedShipping.value),
      description: "Pagamento por boleto",
      delivery_type:
        selectedShipping.service === "store"
          ? "RETIRAR NA LOJA"
          : selectedShipping.service.toUpperCase(),
      shipping_address,
      cart,
    };

    if(couponValue){
      payload = {...payload, coupon: couponValue.code};
    }

    const result = await boletoCheckout(payload, user.token);

    if (result.error) {
      setLoadingCheckout(false);
      return createNotification("error", "Erro", result.message);
    }
    
    const url = result.payment_method.url;
    setLoadingCheckout(false);
    clearCart();
    window.open(url, "_blank");
    createNotification("success", "Sucesso", "Seu pedido foi realizado");
    return history.push("/perfil/meus-pedidos");
  }

  const handleCoupon = async () => {
    if(items.length === 0) return createNotification("error", "Erro", "Insira produtos no carrinho");

    setLoadingCoupon(true);
    const response = await checkCoupon(coupon);

    if (response.error) {
      setLoadingCoupon(false);
      setCouponValue(null);
      setCouponPrice("0");
      return createNotification("error", "Erro", response.message);
    }

    setLoadingCoupon(false);
    setCouponValue(response);

    if(response.percentage){
      setCouponPrice((response.percentage/100) * totalPrice);
    }else {
      setCouponPrice(response.value);
    }
  }

  const handleCheckPaymentType = () => {
    if(!paymentType) return;

    if(paymentType === "card"){
      return setActiveComponent(2);
    }

    return handleBoletoPayment();
  }

  const generateTotalPrice = () => {
    let tempTotal;
    let couponAmount = parseFloat(String(couponPrice).replace(",", "."));
    let totalAmount = parseFloat(String(totalPrice).replace(",", "."));

    if(couponValue){
      if(totalAmount - couponAmount <= 0){
        tempTotal = 0.0;
      }else {
        tempTotal = totalAmount - couponAmount;
      }
    }else {
      tempTotal = totalAmount;
    }

    if(selectedShipping.value !== null){
      let shippingValue = parseFloat(String(selectedShipping.value).replace(",", "."));
      tempTotal += shippingValue;
    }

    setTotalAmount(tempTotal.toFixed(2));
  }

  if(items.length === 0) return <Redirect to="/inicio" />;

  return (
    <Grid columns={1} style={{ padding: "2em 4em" }} stackable>
      <Grid.Row
        style={{
          justifyContent: activeComponent === 2 ? "center" : null,
          height: activeComponent === 3 ? "50vh" : null,
        }}
      >
        <Grid.Column width={10}>
          <CheckoutStep status={activeComponent} />
          {handleStepComponent()}
        </Grid.Column>
        {activeComponent !== 3 && (
          <Grid.Column width={6}>
            <Segment>
              <h3>Resumo do pedido</h3>
              {items.map((item) => (
                <div className="row align-items-center cart-recipe-preview-container">
                  <img src={item.image} alt={item.name} />
                  <div className="row align-items-center justify-content-between">
                    <strong>
                      {item.count}x {item.name}
                    </strong>
                    <p>R${item.price.toFixed(2)}</p>
                  </div>
                </div>
              ))}
              <Segment className="cart-recipe">
                <strong>{`${items.length} ${
                  items.length > 1 ? "produtos" : "produto"
                }`}</strong>
                <Divider />
                <div className="row align-items-center justify-content-between">
                  <p>Subtotal:</p>
                  <p>R$ {totalPrice}</p>
                </div>
                {couponValue && (
                  <div className="row align-items-center justify-content-between">
                    <p>Cupom:</p>
                    <p>R$ {parseFloat(couponPrice).toFixed(2)}</p>
                  </div>
                )}
                <div className="row align-items-center justify-content-between">
                  <p>Entrega:</p>
                  <p>
                    {selectedShipping.value === null
                      ? "Calcule o frete"
                      : `R$${selectedShipping.value}`}
                  </p>
                </div>
                <Divider />
                <div className="cart-recipe-total row align-items-center justify-content-between">
                  <p>Total:</p>
                  <p>
                    R$ {totalAmount}
                  </p>
                </div>
              </Segment>
              <Segment className="cart-recipe">
                <strong>Cupom de Desconto</strong>
                <Divider />
                <div className="column calc-shipping-container">
                  <strong>Digite o código do seu cupom de desconto: </strong>
                  <div
                    className={
                      loadingCoupon ? "ui input loading coupon-input" : "ui input action coupon-input"
                    }
                  >
                    <input
                      value={coupon}
                      onChange={(e) => setCoupon(e.target.value.toUpperCase())}
                      placeholder="00000000"
                    />
                    <button
                      disabled={coupon.length < 8 || loadingCoupon}
                      onClick={handleCoupon}
                      className={`ui button ${
                        coupon.length < 8 && "disabled"
                      }`}
                    >
                      {loadingCoupon ? (
                        <i aria-hidden="true" className="spinner icon"></i>
                      ) : (
                        "Adicionar cupom"
                      )}
                    </button>
                  </div>
                </div>
              </Segment>
              {loadingCalc && (
                <i
                  style={{ alignSelf: "center" }}
                  aria-hidden="true"
                  className="spinner icon"
                ></i>
              )}
              {sedexShipping && pacShipping && activeComponent === 0 && (
                <>
                  <h5>Selecione uma opção de envio:</h5>
                  <CheckContainer
                    className="shipping-option-container"
                    checked={selectedShipping.service === "sedex"}
                    onClick={() => handleSelectShipping("sedex")}
                  >
                    <div className="column">
                      <strong>Sedex</strong>
                      <p>
                        <b>Valor: </b>
                        R$ {sedexShipping.Valor}
                      </p>
                      <p>
                        <b>Prazo: </b>
                        {parseInt(sedexShipping.PrazoEntrega) > 1
                          ? `${sedexShipping.PrazoEntrega} dias`
                          : `${sedexShipping.PrazoEntrega} dia`}
                      </p>
                    </div>
                  </CheckContainer>
                  <CheckContainer
                    className="shipping-option-container"
                    checked={selectedShipping.service === "pac"}
                    onClick={() => handleSelectShipping("pac")}
                  >
                    <div className="column">
                      <strong>PAC</strong>
                      <p>
                        <b>Valor: </b>
                        R$ {pacShipping.Valor}
                      </p>
                      <p>
                        <b>Prazo: </b>
                        {parseInt(pacShipping.PrazoEntrega) > 1
                          ? `${pacShipping.PrazoEntrega} dias`
                          : `${pacShipping.PrazoEntrega} dia`}
                      </p>
                    </div>
                  </CheckContainer>
                  </>)
                }
                {activeComponent === 0 && selectedAddress != null && (
                  <>
                  {store.pickup && (
                    <CheckContainer
                      className="shipping-option-container"
                      checked={selectedShipping.service === "store"}
                      onClick={() => handleSelectShipping("store")}
                    >
                      <div className="column">
                        <strong>Retirar na loja</strong>
                        <p>
                          <b>Valor: </b>
                          R$ 00,00
                        </p>
                        <p>
                          {store.address_street}, {store.address_number} -{" "}
                          {store.address_district}
                        </p>
                        <p>
                          {store.address_city} - {store.address_state}
                        </p>
                      </div>
                    </CheckContainer>
                  )}
                  <CheckContainer
                    className="shipping-option-container"
                    checked={selectedShipping.service === "freeShipping"}
                    onClick={() => handleSelectShipping("freeShipping")}
                  >
                    <div className="column">
                      <strong>Entrega Grátis</strong>
                      <p>
                        <b>Valor: </b>
                        R$ 00,00
                      </p>
                      <p>
                        Para compras pagas a vista no cartão ou boleto
                      </p>
                    </div>
                  </CheckContainer>
                </>
              )}
              {activeComponent === 0 && (
                <Button
                  fluid
                  primary
                  disabled={
                    selectedShipping.service === null
                      ? true
                      : false || items.length === 0
                  }
                  style={{ marginTop: "1em" }}
                  onClick={() => setActiveComponent(activeComponent + 1)}
                >
                  Avançar
                </Button>
              )}
              {activeComponent === 1 && (
                <Button
                  fluid
                  primary
                  disabled={paymentType === null ? true : false || loadingCheckout}
                  style={{ marginTop: "1em" }}
                  onClick={() => handleCheckPaymentType()}
                >
                  {loadingCheckout ? (
                    <i
                      style={{ alignSelf: "center" }}
                      aria-hidden="true"
                      className="spinner icon"
                    ></i>
                  ) : (
                    "Confirmar pagamento"
                  )}
                </Button>
              )}
              {activeComponent === 2 && (
                <Button
                  fluid
                  primary
                  disabled={cardInfo === null ? true : false || loadingCheckout}
                  style={{ marginTop: "1em" }}
                  onClick={() => handlePayment()}
                >
                  {loadingCheckout ? (
                    <i
                      style={{ alignSelf: "center" }}
                      aria-hidden="true"
                      className="spinner icon"
                    ></i>
                  ) : (
                    "Confirmar pagamento"
                  )}
                </Button>
              )}
            </Segment>
          </Grid.Column>
        )}
      </Grid.Row>
    </Grid>
  );
}
