import React, { useState, useEffect } from "react";
import { Segment, Container, Grid, Header, List } from "semantic-ui-react";
import { Link } from "react-router-dom";
import { useStore } from "../../contexts/store";
import { getSectors } from "../../providers/productProvider";
import createNotification from "../../utils/notification";
import "./styles.css";
import Visa from "../../assets/images/visa.png";
import MasterCard from "../../assets/images/mastercard.png";
import Aura from "../../assets/images/aura.png";
import Elo from "../../assets/images/elo.png";
import AmericanExpress from "../../assets/images/american-express.png";
import BanesCard from "../../assets/images/banes-card.png";
import Hiper from "../../assets/images/hiper.png";
import HiperCard from "../../assets/images/hiper-card.png";
import JBC from "../../assets/images/jbc.png";
import pac from "../../assets/images/pac.png";
import sedex from "../../assets/images/sedex.png";
import retirada from "../../assets/images/retirada.png";
import Develop from '../Develop';

export default function SecondFooter() {
  const { store } = useStore();
  const [showDevelopedBy, setShowDevelopedBy] = useState(true); 
  const [sectors, setSectors] = useState([]); 
  
  useEffect(() => {
    setShowDevelopedBy(store.name === "Acqua Menina" ? false : true);
  }, [store]);

  useEffect(() => {
    getSectorsData();
  }, []);

  async function getSectorsData() {
    const response = await getSectors();

    if (response.error)
      return createNotification(
        "error",
        "Erro",
        "Ocorreu um erro ao carregar a lista de setores"
      );

    return setSectors(response);
  }

  return (
    <>
      <Container className="footer-container">
        {store && (
          <Grid divided stackable>
            <Grid.Row>
              <Grid.Column width={4}>
                <Header as="h4" content="ATENDIMENTO" />
                {store.address_postcode !== '00000-000' && (
                  <>
                  <h5>Endereço</h5>
                  <p>
                    {store.address_street}, {store.address_number} -{" "}
                    {store.address_district}
                  </p>
                  <p>
                    {store.address_city} - {store.address_state}
                  </p>
                  <p>{store.address_complement}</p>
                  <p>{store.address_postcode}</p>
                  </>
                )}
                {store.phone && (
                  <>
                  <h5>Telefone</h5>
                  <p>{store.phone}</p>
                  </>
                )}
                {store.whatsapp_number && (
                  <>
                  <h5>Whatapp</h5>
                  <p>{store.whatsapp_number}</p>
                  </>
                )}
              </Grid.Column>
              <Grid.Column width={4}>
                <Header as="h4" content="SETORES" />
                <List link>
                  {sectors.map(sector => (
                    <List.Item as={Link} to={`/setor/${sector.id}`} key={sector.id}>
                      {sector.name}
                    </List.Item>
                  ))}
                </List>
              </Grid.Column>
              <Grid.Column width={4}>
                <Header as="h4" content="INSTITUCIONAL" />
                <List link>
                  <List.Item as={Link} to="/sobre">
                    Quem somos
                  </List.Item>
                  <List.Item as={Link} to="/contato">
                    Contato
                  </List.Item>
                  <List.Item as={Link} to="/duvidas-frequentes">
                    Dúvidas frequentes
                  </List.Item>
                </List>
              </Grid.Column>
              <Grid.Column width={4}>
                <Header as="h4" content="REDES SOCIAIS" />
                <List link>
                  {store.instagram && (
                    <List.Item
                      as="a"
                      href={`https://instagram.com/${store.instagram}`}
                      target="_blank"
                    >
                      Instagram
                    </List.Item>
                  )}
                  {store.twitter && (
                    <List.Item
                      as="a"
                      href={`https://twitter.com/${store.twitter}`}
                      target="_blank"
                    >
                      Twitter
                    </List.Item>
                  )}
                  <br />
                  {store.facebook && (
                    <iframe 
                      src={`https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2F${store.facebook}&tabs&width=340&height=70&small_header=true&adapt_container_width=true&hide_cover=false&show_facepile=false&appId`}
                      width="340" 
                      height="70" 
                      style={{border: "none", overflow: "hidden"}}
                      scrolling="no" 
                      frameBorder="0" 
                      allowFullScreen="true" 
                      allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share">
                    </iframe>
                  )}
                </List>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        )}
      </Container>
      <StoreShippingAndPayment />
      {showDevelopedBy && (
        <div className="developedByContainer">
          <Develop />
        </div>
      )}
    </>
  );
}

const StoreShippingAndPayment = () => {
  return (
    <Grid className="storeShippingAndPaymentContainer" divided stackable>
      <Grid.Row>
        <PaymentOptions />
        <ShippingOptions />
      </Grid.Row>
    </Grid>
  );
}

const ShippingOptions = () => {
  return (
    <Grid.Column width={4}>
      <Header as="h4" content="FORMAS DE ENVIO" />
      <Grid.Row>
        <img className="footer-shipping-icon" alt="pac" src={pac} />
        <img
          className="footer-shipping-icon"
          alt="sedex"
          src={sedex}
        />
        <img className="footer-shipping-icon" alt="retirada" src={retirada} />
      </Grid.Row>
    </Grid.Column>
  )
};

const PaymentOptions = () => {
  return (
    <Grid.Column width={4}>
      <Header as="h4" content="FORMAS DE PAGAMENTO" />
      <Grid.Row>
        <img className="footer-flag-icon" alt="Visa" src={Visa} />
        <img
          className="footer-flag-icon"
          alt="Visa"
          src={MasterCard}
        />
        <img className="footer-flag-icon" alt="Visa" src={Elo} />
        <img
          className="footer-flag-icon"
          alt="Visa"
          src={BanesCard}
        />
        <img
          className="footer-flag-icon"
          alt="Visa"
          src={AmericanExpress}
        />
      </Grid.Row>
      <Grid.Row>
        <img className="footer-flag-icon" alt="Visa" src={Hiper} />
        <img
          className="footer-flag-icon"
          alt="Visa"
          src={HiperCard}
        />
        <img className="footer-flag-icon" alt="Visa" src={Aura} />
        <img className="footer-flag-icon" alt="Visa" src={JBC} />
      </Grid.Row>
    </Grid.Column>
  )
};