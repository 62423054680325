import React, { createContext, useState, useEffect, useContext } from "react";
import { PIXEL_TYPE_CHOICES, TrackPixelEvent } from "../utils/facebookPixel";
import { useStore } from "./store";

const CartContext = createContext({
  items: null,
  addItem: null,
  clear: null,
  removeItem: null,
  changeItemQuantity: null,
});

export const CartProvider = ({ children }) => {
  const { store, pixelReady } = useStore();
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function loadStoragedData() {
      const storeTag = store.name.replace(" ", "").toLowerCase();
      const storagedCart = localStorage.getItem(`@${storeTag}:cart`);

      if (storagedCart) {
        setItems(JSON.parse(storagedCart).cart);
      }

      setLoading(false);
    }

    if (Object.keys(store).length > 0)
      loadStoragedData();
  }, [store]);

  useEffect(() => {
    if (Object.keys(store).length > 0) {
      const storeTag = store.name.replace(" ", "").toLowerCase();
      localStorage.setItem(`@${storeTag}:cart`, JSON.stringify({ cart: items }));
    }

  }, [items, store]);

  function addItem(item) {
    let checkIfAdded = false;

    let newItemsList = items.map((cartItem) => {
      if (cartItem.id === item.id) {
        let newItem = { ...cartItem, count: cartItem.count + item.count };
        checkIfAdded = true;
        return newItem;
      }
      return cartItem;
    });

    if (checkIfAdded) {
      setItems(newItemsList);
    } else {
      newItemsList = [...items, item];
      setItems(newItemsList);
    }
    if(pixelReady) {
      TrackPixelEvent(store.events, PIXEL_TYPE_CHOICES.AddToCart);
    }
  }

  function removeItem(id) {
    setItems(items.filter((cartItem) => cartItem.id !== id));
  }

  function changeItemQuantity(id, value) {
    setItems(
      items.map((cartItem) => {
        if (cartItem.id === id) {
          const currentItem = { ...cartItem, count: value };
          return currentItem;
        } else {
          return cartItem;
        }
      })
    );
  }

  function clear() {
    if (Object.keys(store).length > 0) {
      const storeTag = store.name.replace(" ", "").toLowerCase();
      localStorage.removeItem(`@${storeTag}:cart`);
      setItems([]);
    }
  }

  return (
    <CartContext.Provider
      value={{
        items,
        addItem,
        removeItem,
        changeItemQuantity,
        clear,
      }}
    >
      {!loading && children}
    </CartContext.Provider>
  );
};

export function useCart() {
  const context = useContext(CartContext);

  return context;
}
