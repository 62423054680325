import React, { useState, useEffect, Fragment } from "react";
import { Accordion, Icon, Grid, GridColumn } from "semantic-ui-react";
import { getFAQ } from "../../providers/GeneralProvider";
import createNotification from "../../utils/notification";
import "./styles.css";

function FAQ() {
  const [faq, setFaq] = useState([]);
  const [activeIndex, setActiveIndex] = useState(0);

  useEffect(() => {
    async function doGetFaq() {
      const result = await getFAQ();

      if (result.error) {
        return createNotification(
          "error",
          "Erro",
          "Ocorreu um erro ao tentar carregar a lista de perguntas"
        );
      }

      return setFaq(result);
    }

    doGetFaq();
  }, []);

  function handleClick(e, titleProps) {
    const { index } = titleProps;

    if (index === activeIndex) {
      return setActiveIndex(null);
    }

    setActiveIndex(index);
  }

  return (
    <div className="faq-container">
      <h2>Dúvidas frequentes</h2>
      <Accordion fluid styled>
        {faq.map((faqQuestion, index) => (
          <Fragment key={faqQuestion.id}>
            <Accordion.Title
              active={activeIndex === index}
              index={index}
              onClick={handleClick}
            >
              <Icon name="dropdown" />
              {faqQuestion.question}
            </Accordion.Title>
            <Accordion.Content active={activeIndex === index}>
              <p>{faqQuestion.answer}</p>
            </Accordion.Content>
          </Fragment>
        ))}
      </Accordion>
    </div>
  );
}

export default FAQ;
