import React from "react";
import { Grid, Divider, Image } from "semantic-ui-react";
import { Link } from "react-router-dom";
import "./styles.css";

//format dates
import { parseISO, format } from "date-fns";
import ptbr from "date-fns/locale/pt-BR";

import OrderStep from "../OrderStep";

export default function OrderDetail({ order }) {
  console.log(order);
  return (
    <Grid stackable>
      <Grid.Column width={16} className="order-detail-info-container">
        <strong>Resumo do pedido #{order.code}</strong>
        <table>
          <tbody>
            <tr>
              <th>Data do pedido:</th>
              <th>{format(parseISO(order.created_at), "dd/MM/yyyy")}</th>
            </tr>
            <tr>
              <th>Valor total:</th>
              <th>R${parseFloat(order.total_amount).toFixed(2)}</th>
            </tr>
            <tr>
              <th>Forma de pagamento:</th>
              <th>{order.payment_type}</th>
            </tr>
            {order.payment_type === "Boleto" && (
              <tr>
                <th>Boleto Gerado:</th>
                <th><a href={order.brazilian_boleto} target="_blank" rel="noopener noreferrer">Link</a></th>
              </tr>
            )}
            <tr>
              <th>Serviço de entrega:</th>
              <th>{order.delivery_type}</th>
            </tr>
            <tr>
              <th>Endereço de envio:</th>
              <th>
                <p>{order.recipient}</p>
                <p>
                  {order.address_street}, {order.address_number}
                </p>
                <p>{order.address_district}</p>
                <p>{order.address_postcode}</p>
                <p>{order.address_complement}</p>
              </th>
            </tr>
            {order.tracking_code !== "" &&
              order.delivery_type !== "RETIRAR NA LOJA" && (
                <tr>
                  <th>Código de rastreio:</th>
                  <th>
                    {order.tracking_code}{" "}
                    <a
                      href="https://www2.correios.com.br/sistemas/rastreamento/default.cfm"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      (Link para rastreio)
                    </a>
                  </th>
                </tr>
              )}
          </tbody>
        </table>
      </Grid.Column>
      <Grid.Column width={16}>
        <strong>Status do pedido</strong>
        <OrderStep status={order.status} type={order.delivery_type} />
      </Grid.Column>
      <Grid.Column width={16}>
        <h5>Produtos ({order.list_itens.length})</h5>
        {order.list_itens.map((orderItem) => (
          <div className="order-product-container" key={orderItem.product.id}>
            <div className="row align-items-center">
              <Image
                src={orderItem.product.album[0].image}
                alt={orderItem.product.name}
              />
              <div className="column">
                <Link
                  style={{ color: "black" }}
                  to={`/produto/${orderItem.product.product_base.id}`}
                >
                  {orderItem.product.name}
                </Link>
                <p>
                  <b>Preço:</b> R$
                  {(
                    orderItem.quantity * parseFloat(orderItem.product.value)
                  ).toFixed(2)}
                </p>
              </div>
            </div>
            <Divider />
          </div>
        ))}
      </Grid.Column>
    </Grid>
  );
}
