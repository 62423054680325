import React from "react";
import ResponsiveLayout from "../../components/Header";
import Footer from "../../components/Footer";
import SecondFooter from "../../components/SecondFooter";
import CookieSnackbar from "../../components/CookieSnackbar";
import { withRouter } from "react-router-dom";

class MainLayout extends React.Component {

  constructor(props){
    super(props);
    this.footerOption = process.env.REACT_APP_FOOTER_LAYOUT;
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      window.scrollTo(0, 0);
    }
  }

  renderFooter(){
    if(!parseInt(this.footerOption) || parseInt(this.footerOption) === 1){
      return <Footer />
    }else if(parseInt(this.footerOption) === 2){
      return <SecondFooter />
    }
  }

  render() {
    return (
      <ResponsiveLayout>
        {this.props.children}
        {this.renderFooter()}
        <CookieSnackbar />
      </ResponsiveLayout>
    );
  }
}

export default withRouter(MainLayout);
