import React, { useState, useEffect } from "react";

import {
  Segment,
  Grid,
  Divider,
  Button,
  Header,
  Select,
  Container,
} from "semantic-ui-react";
import { useParams, useHistory } from "react-router-dom";
import createNotification from "../../utils/notification";

import QuantityPicker from "../../components/QuantityPicker";
import ProductEvaluation from "../../components/ProductEvaluation";
import ProductCarousel from "../../components/ProductCarousel";
import ProductEvaluationsList from "../../components/ProductEvaluationsList";
import InputMask from "react-input-mask";
import { FaCreditCard } from "react-icons/fa";

import { useCart } from "../../contexts/cart";
import { useStore } from "../../contexts/store";
import { useAuth } from "../../contexts/auth";
import { calcularFrete } from "../../providers/correiosProvider";
import { getProduct } from "../../providers/productProvider";

import "./styles.css";
import { PIXEL_TYPE_CHOICES, TrackPixelEvent } from "../../utils/facebookPixel";

export default function Product() {
  const params = useParams();
  const history = useHistory();
  const { store, pixelReady } = useStore();
  const { user, signed } = useAuth();
  const { items, addItem } = useCart();

  const [products, setProducts] = useState(null);
  const [activeProduct, setActiveProduct] = useState(null);
  const [features, setFeatures] = useState({});
  const [featuresSelected, setFeaturesSelected] = useState({});
  const [quantity, setQuantity] = useState(1);
  const [postcode, setPostcode] = useState("");
  const [loadingCalc, setLoadingCalc] = useState(false);
  const [sedexShipping, setSedexShipping] = useState(null);
  const [pacShipping, setPacShipping] = useState(null);
  const [sendEvaluationLoading, setSendEvaluationLoading] = useState(false);

  useEffect(() => {
    async function getProductData() {
      const result = await getProduct(params.id);

      if (result.error) {
        return createNotification(
          "error",
          "Erro",
          "Ocorreu um erro ao carregar os dados do produto"
        );
      }
      
      let tempFeatures = {};
      let tempFeaturesSelected = {};
      result.map((product, index) => {
        product.list_properties.map((prop) => {
          if (!tempFeatures.hasOwnProperty(prop.feature.title)) {
            tempFeatures[prop.feature.title] = [prop.option.title];
            if (index === 0)
              tempFeaturesSelected[prop.feature.title] = prop.option.title;
          } else {
            if (!tempFeatures[prop.feature.title].includes(prop.option.title))
              tempFeatures[prop.feature.title].push(prop.option.title);
          }
        });
      });

      setFeatures(tempFeatures);
      setFeaturesSelected(tempFeaturesSelected);
      setProducts(result);
      setActiveProduct(result[0]);
    }

    getProductData();
    
    if(pixelReady) {
      TrackPixelEvent(store.events, PIXEL_TYPE_CHOICES.ViewContent);
    }
  }, []);

  function handleChangeFeatureSelected(key, value) {
    let tempFeaturesSelected = { ...featuresSelected };
    tempFeaturesSelected[key] = value;

    let tempActiveProductFiltered = products.filter((product) => {
      let tempFeatures = {};
      product.list_properties.map((prop) => {
        tempFeatures[prop.feature.title] = prop.option.title;
      });

      if (
        JSON.stringify(tempFeatures) === JSON.stringify(tempFeaturesSelected)
      ) {
        return product;
      }
    })[0];

    if (!tempActiveProductFiltered) {
      tempFeaturesSelected = {};

      tempActiveProductFiltered = products.filter((product) => {
        let hasValue = false;

        for (let i = 0; i < product.list_properties.length; i++) {
          if (product.list_properties[i].option.title === value) {
            hasValue = true;
            break;
          }
        }

        if (hasValue) {
          return product;
        }
      })[0];

      tempActiveProductFiltered.list_properties.map(
        (prop) => (tempFeaturesSelected[prop.feature.title] = prop.option.title)
      );
    }

    setActiveProduct(tempActiveProductFiltered);
    setFeaturesSelected(tempFeaturesSelected);
  }

  async function handleCalcShipping() {
    setLoadingCalc(true);
    const args = {
      origem: store.address_postcode,
      destino: postcode.replace(/\D/g, ""),
      peso: "0.5",
      formato: 1,
      comprimento: 15,
      altura: 1,
      largura: 10,
      diametro: 0,
    };

    const response = await calcularFrete(args);

    if (response.error === true) {
      setLoadingCalc(false);
      return createNotification(
        "error",
        "Erro",
        "Ocorreu um erro ao calcular o frete"
      );
    }
    setSedexShipping(response.sedex);
    setPacShipping(response.pac);
    setLoadingCalc(false);
  }

  function handleAddItem() {
    const item = {
      id: activeProduct.id,
      productBaseId: activeProduct.product_base.id,
      image: activeProduct.album[0].image,
      name: activeProduct.name,
      count: quantity,
      price:
        parseFloat(activeProduct.promotional_value) > 0
          ? parseFloat(activeProduct.promotional_value)
          : parseFloat(activeProduct.value),
      weight: activeProduct.weight,
      freeShipping: activeProduct.free_shipping,
    };

    addItem(item);

    if(signed){
      return history.push("/carrinho");
    }else {
      return history.push({
        pathname: "/login",
        state: {
          from: {
            pathname: "/produto"
          }
        }
      });
    }
  }

  return (
    <>
      <Grid columns="equal" className="product-detail-main-container" stackable>
        <Grid.Row>
          <Grid.Column>
            <Segment style={{ padding: "2em" }}>
              {activeProduct && (
                <ProductCarousel
                  alt={activeProduct.product_base.name}
                  srcList={activeProduct.album.map((image) => {
                    return {
                      small: image.image_small,
                      medium: image.image_medium,
                      large: image.image,
                    };
                  })}
                />
              )}
            </Segment>
          </Grid.Column>
          <Grid.Column>
            <Segment className="product-content">
              {activeProduct && (
                <>
                  <h3 className="product-title">
                    {activeProduct && activeProduct.product_base.name}
                  </h3>
                  {activeProduct.stock > 0 && (
                    <div className="row align-items-center price-container">
                      {parseFloat(activeProduct.promotional_value) > 0 && (
                        <p className="promotional-price">
                          R$
                          {" " +
                            parseFloat(activeProduct.promotional_value).toFixed(
                              2
                            )}
                        </p>
                      )}
                      <p
                        className={
                          parseFloat(activeProduct.promotional_value) > 0
                            ? "current-price not-valid"
                            : "current-price"
                        }
                      >
                        R${" " + parseFloat(activeProduct.value).toFixed(2)}
                      </p>
                    </div>
                  )}
                  {activeProduct.installment !== null &&
                    activeProduct.stock > 0 && (
                      <div className="row" style={{ margin: ".5em 0" }}>
                        <FaCreditCard size={18} color="#2185d0" />
                        <p className="installments">
                          <strong>{activeProduct.installment}x</strong> de{" "}
                          <strong>
                            R$
                            {(
                              parseFloat(
                                parseFloat(activeProduct.promotional_value) > 0
                                  ? activeProduct.promotional_value
                                  : activeProduct.value
                              ) / parseFloat(activeProduct.installment)
                            ).toFixed(2) + " "}
                          </strong>
                          s/ juros
                        </p>
                      </div>
                    )}
                  <Divider />
                  {activeProduct.stock > 0 ? (
                    <Container fluid className="row align-items-center">
                      {Object.keys(features).map((feature) => (
                        <div
                          className="column product-filter-container"
                          key={feature}
                        >
                          <strong style={{ fontSize: 12 }}>{feature}</strong>
                          <Select
                            fluid
                            style={{ height: 40 }}
                            value={featuresSelected[feature]}
                            onChange={(e, { value }) =>
                              handleChangeFeatureSelected(feature, value)
                            }
                            options={features[feature].map((option, index) => {
                              return { value: option, text: option };
                            })}
                          />
                        </div>
                      ))}
                      <QuantityPicker
                        value={quantity}
                        onChange={setQuantity}
                        maxValue={activeProduct.stock}
                      />
                    </Container>
                  ) : (
                    <p>Produto indisponível</p>
                  )}
                  {activeProduct.stock > 0 && (
                    <Button
                      style={{ margin: "1em 0" }}
                      primary
                      fluid
                      disabled={activeProduct.stock === 0}
                      onClick={handleAddItem}
                    >
                      Adicionar no carrinho
                    </Button>
                  )}
                </>
              )}
              <div className="column calc-shipping-container">
                <strong>Calcular frete: </strong>
                <div
                  className={
                    loadingCalc ? "ui input fluid loading" : "ui input action"
                  }
                >
                  <InputMask
                    mask="99999-999"
                    value={postcode}
                    onChange={(e) => setPostcode(e.target.value)}
                    placeholder="00000-000"
                  />
                  <button
                    disabled={
                      postcode.replace(/\D/g, "").length < 8 || loadingCalc
                    }
                    onClick={handleCalcShipping}
                    className={`ui button ${
                      postcode.replace(/\D/g, "").length < 8 && "disabled"
                    }`}
                  >
                    {loadingCalc ? (
                      <i aria-hidden="true" className="spinner icon"></i>
                    ) : (
                      "Ok"
                    )}
                  </button>
                </div>
                <a
                  target="_blank"
                  href="http://www.buscacep.correios.com.br/sistemas/buscacep/"
                >
                  Não sei meu CEP
                </a>

                {sedexShipping && pacShipping && (
                  <table className="shipping-info-container">
                    <thead>
                      <tr>
                        <th>Serviço</th>
                        <th>Preço</th>
                        <th>Prazo</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <th>Sedex</th>
                        <th>{sedexShipping.Valor}</th>
                        <th>
                          {parseInt(sedexShipping.PrazoEntrega) > 1
                            ? `${sedexShipping.PrazoEntrega} dias`
                            : `${sedexShipping.PrazoEntrega} dia`}
                        </th>
                      </tr>
                      <tr>
                        <th>PAC</th>
                        <th>{pacShipping.Valor}</th>
                        <th>
                          {parseInt(pacShipping.PrazoEntrega) > 1
                            ? `${pacShipping.PrazoEntrega} dias`
                            : `${pacShipping.PrazoEntrega} dia`}
                        </th>
                      </tr>
                    </tbody>
                  </table>
                )}
              </div>
            </Segment>
          </Grid.Column>
        </Grid.Row>
        {activeProduct && (
          <>
            <Grid.Row style={{ padding: "0 1em" }}>
              <Segment style={{ width: "100%" }}>
                <Header as="h5">Descrição</Header>
                <p>{activeProduct.product_base.description}</p>
              </Segment>
            </Grid.Row>
            <Grid.Row columns="equal" style={{ margin: "1em 0 0 0" }}>
              <Grid.Column style={{ padding: "0 1em" }}>
                <ProductEvaluation
                  loading={sendEvaluationLoading}
                  setLoading={setSendEvaluationLoading}
                  productId={activeProduct.product_base.id}
                />
              </Grid.Column>

              <Grid.Column style={{ padding: "0 1em" }}>
                {!sendEvaluationLoading && (
                  <ProductEvaluationsList
                    average={activeProduct.product_base.average_grade}
                    productId={activeProduct.product_base.id}
                  />
                )}
              </Grid.Column>
            </Grid.Row>
          </>
        )}
      </Grid>
    </>
  );
}
