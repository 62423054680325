import React, { useState } from "react";
import { Form, Button } from "semantic-ui-react";
import InputMask from "react-input-mask";
import { useAuth } from "../../contexts/auth";
import { getAddress } from "../../providers/correiosProvider";
import { stateOptions } from "../../utils/data";
import createNotification from "../../utils/notification";

export default function AddressEditing() {
  const { user, updateUser } = useAuth();

  const [street, setStreet] = useState(user.address_street);
  const [state, setState] = useState(user.address_state);
  const [postcode, setPostcode] = useState(user.address_postcode);
  const [city, setCity] = useState(user.address_city);
  const [district, setDistrict] = useState(user.address_district);
  const [number, setNumber] = useState(
    user.address_number ? user.address_number : ""
  );
  const [complement, setComplement] = useState(user.address_complement);
  const [loading, setLoading] = useState(false);
  const [loadingSearchAddress, setLoadingSearchAddress] = useState(false);

  async function handleUpdateAddress(e) {
    e.preventDefault();
    setLoading(true);

    const payload = {
      address_street: street,
      address_state: state,
      address_postcode: postcode.replace(/\D/g, ""),
      address_city: city,
      address_district: district,
      address_number: number,
      address_complement: complement,
    };

    const result = await updateUser(user.id, payload);

    if (!result) {
      setLoading(false);
      return createNotification(
        "error",
        "Erro",
        "Ocorreu um erro ao tentar atualizar seu endereço"
      );
    }

    setLoading(false);
    return createNotification(
      "success",
      "Sucesso",
      "Endereço atualizado com sucesso"
    );
  }

  async function handleSearchAddress(){
    setLoadingSearchAddress(true);
    const result = await getAddress(postcode);

    if(result.error){
      setLoadingSearchAddress(false);
      return createNotification("error", "Erro", result.message);
    };

    setLoadingSearchAddress(false);

    setStreet(result.logradouro ? result.logradouro : "");
    setDistrict(result.bairro ? result.bairro : "");
    setCity(result.cidade ? result.cidade : "");
    setState(result.uf ? result.uf : "");
  }

  return (
    <>
      <h3>Endereço de Entrega</h3>
      <Form style={{ padding: "0 1em" }} onSubmit={handleUpdateAddress}>
      <Form.Field width={8} required>
          <label>CEP:</label>
          <div className="row align-items-center">
            <div className="row align-items-center">
              <InputMask
                mask="99999-999"
                placeholder="00000-00"
                value={postcode}
                onChange={(e) =>
                  setPostcode(e.target.value)
                }
                required
              />
            </div>
            <Button
              className="cart-toggle-icon"
              icon="search icon"
              onClick={handleSearchAddress}
              type="button"
              disabled={loadingSearchAddress}
            />
          </div>
          <a
            target="_blank"
            href="http://www.buscacep.correios.com.br/sistemas/buscacep/"
            style={{ marginTop: ".5em", fontSize: 12 }}
          >
            Não sei meu CEP
          </a>
        </Form.Field>
        <Form.Group>
          <Form.Field width={8} required>
            <label style={{ fontWeight: "500" }}>Rua:</label>
            <input
              placeholder="Rua"
              value={street}
              onChange={(e) => setStreet(e.target.value)}
              required
            />
          </Form.Field>
          <Form.Field width={6} required>
            <label style={{ fontWeight: "500" }}>Bairro:</label>
            <input
              placeholder="Bairro"
              value={district}
              onChange={(e) => setDistrict(e.target.value)}
              required
            />
          </Form.Field>
          <Form.Field width={2} required>
            <label style={{ fontWeight: "500" }}>Número:</label>
            <input
              placeholder="000"
              value={number}
              onChange={(e) => setNumber(e.target.value)}
              required
            />
          </Form.Field>
        </Form.Group>
        <Form.Group widths="equal">
          <Form.Field required>
            <label style={{ fontWeight: "500" }}>Cidade:</label>
            <input
              placeholder="Cidade"
              value={city}
              onChange={(e) => setCity(e.target.value)}
              required
            />
          </Form.Field>
          <Form.Field required>
            <label style={{ fontWeight: "500" }}>Estado:</label>
            <Form.Select
              options={stateOptions}
              value={state}
              onChange={(e, { value }) => setState(value)}
              required
            />
          </Form.Field>
        </Form.Group>
        <Form.Field>
          <label style={{ fontWeight: "500" }}>Complemento:</label>
          <input
            placeholder="Complemento"
            value={complement}
            onChange={(e) => setComplement(e.target.value)}
          />
        </Form.Field>
        <Form.Field>
          <Button primary fluid disabled={loading}>
            {loading ? (
              <i aria-hidden="true" className="spinner icon"></i>
            ) : (
              "Salvar alterações"
            )}
          </Button>
        </Form.Field>
      </Form>
    </>
  );
}
