/* eslint-disable react-hooks/exhaustive-deps */
import React, {useState, useEffect} from "react";
import { useAuth } from "./contexts/auth";
import {
  HashRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";

import MainLayout from "./containers/MainLayout";

import Home from "./pages/Home";
import Contact from "./pages/Contact";
import FAQ from "./pages/FAQ";
import About from "./pages/About";
import Product from "./pages/Product";
import ProductList from "./pages/ProductList";
import Search from "./pages/Search";
import Cart from "./pages/Cart";
import Checkout from "./pages/Checkout";
import Profile from "./pages/Profile";
import Login from "./pages/Login";
import Register from "./pages/Register";
import PrivacyPolitics from "./pages/PrivacyPolitics";

export default function AuthExample() {
  return (
    <Router>
      <MainLayout>
        <Switch>
          <Route path="/" exact>
            <Redirect to="/inicio" />
          </Route>
          <Route path="/inicio">
            <Home />
          </Route>
          <Route path="/contato">
            <Contact />
          </Route>
          <Route path="/duvidas-frequentes">
            <FAQ />
          </Route>
          <Route path="/politica">
            <PrivacyPolitics />
          </Route>
          <Route path="/sobre">
            <About />
          </Route>
          <UnAuthRoute path="/login">
            <Login />
          </UnAuthRoute>
          <UnAuthRoute path="/cadastro">
            <Register />
          </UnAuthRoute>
          <Route path="/carrinho">
            <Cart />
          </Route>
          <PrivateRoute path="/pagamento">
            <Checkout />
          </PrivateRoute>
          <PrivateRoute path="/perfil/:section/:id?">
            <Profile />
          </PrivateRoute>
          <Route
            path="/setor/:sectionId/:categoria?/:categoryId?/:subcategoria?/:subcategoryId?"
            exact
          >
            <ProductList />
          </Route>
          <Route path="/pesquisa/:searchTerm" exact>
            <Search />
          </Route>
          <Route path="/produto/:id" exact>
            <Product />
          </Route>
        </Switch>
      </MainLayout>
    </Router>
  );
}

function PrivateRoute({ children, ...rest }) {
  const { signed } = useAuth();

  return (
    <Route
      {...rest}
      render={({ location }) =>
        signed ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: location },
            }}
          />
        )
      }
    />
  );
}

function UnAuthRoute({ children, ...rest }) {
  const { signed } = useAuth();
  
  return (
    <Route
      {...rest}
      render={({ location }) =>
        signed ? (
          <Redirect
            to={{
              pathname: "/inicio",
              state: { from: location },
            }}
          />
        ) : (
          children
        )
      }
    />
  );
}
