import React, { useState, useEffect } from "react";
import { Segment, Divider, Form, Button } from "semantic-ui-react";
import InputMask from "react-input-mask";
import CheckContainer from "../CheckContainer";
import { useAuth } from "../../contexts/auth";
import createNotification from "../../utils/notification";
import { getAddress } from "../../providers/correiosProvider";

import "./styles.css";
import { PIXEL_TYPE_CHOICES, TrackPixelEvent } from "../../utils/facebookPixel";
import { useStore } from "../../contexts/store";

const CheckoutAddress = ({ checked, setChecked, saveAddress, disabled }) => {
  const { store, pixelReady } = useStore();
  const { user } = useAuth();

  // const [phoneNumber, setPhoneNumber] = useState("");
  // const [cellphoneNumber, setCellphoneNumber] = useState("");

  const [customAddress, setCustomAddress] = useState({
    firstName: "",
    lastName: "",
    street: "",
    state: "",
    postcode: "",
    city: "",
    district: "",
    number: "",
    complement: "",
  });
  const [loadingSearchAddress, setLoadingSearchAddress] = useState(false);

  function checkIfFilled(obj) {
    let filled = true;

    for (let key in obj) {
      if (obj[key] === "" && key !== "complement") {
        filled = false;
        break;
      }
    }

    return filled;
  }

  useEffect(() => {
    if(pixelReady) {
      TrackPixelEvent(store.events, PIXEL_TYPE_CHOICES.InitiateCheckout);
    }
  }, [])

  useEffect(() => {
    if (
      checkIfFilled(customAddress) &&
      customAddress.postcode.replace(/\D/g, "").length === 8
    ) {
      const payload = {
        address_street: customAddress.street,
        address_state: customAddress.state,
        address_district: customAddress.district,
        address_number: customAddress.number,
        address_postcode: customAddress.postcode.replace(/\D/g, ""),
        address_complement: customAddress.complement,
        address_city: customAddress.city,
        address_to: `${customAddress.firstName} ${customAddress.lastName}`,
      };

      saveAddress(payload);
    }
  }, [customAddress]);

  function handleChangeCustomAddress(prop, value) {
    setCustomAddress({ ...customAddress, [prop]: value });
  }

  function handleSavedAddress() {
    if (!checked) {
      const payload = {
        address_street: user.address_street,
        address_state: user.address_state,
        address_district: user.address_district,
        address_number: user.address_number,
        address_postcode: user.address_postcode,
        address_complement: user.address_complement,
        address_city: user.address_city,
        address_to: `${user.first_name} ${user.last_name}`,
      };

      saveAddress(payload);
      setCustomAddress({
        firstName: "",
        lastName: "",
        street: "",
        state: "",
        postcode: "",
        city: "",
        district: "",
        number: "",
        complement: "",
      });
    } else {
      saveAddress(null);
    }

    setChecked(!checked);
  }

  async function handleSearchAddress(){
    setLoadingSearchAddress(true);
    const result = await getAddress(customAddress.postcode);

    if(result.error){
      setLoadingSearchAddress(false);
      return createNotification("error", "Erro", result.message);
    };

    setLoadingSearchAddress(false);

    return setCustomAddress({
      ...customAddress,
      street: result.logradouro,
      state: result.uf,
      city: result.cidade,
      district: result.bairro,
    })
  }

  return (
    <Segment className={"checkout-address"}>
      {user.address_postcode && user.address_postcode !== "" && (
        <>
          <CheckContainer
            checked={checked}
            onClick={disabled ? () => {} : () => handleSavedAddress()}
          >
            <div className="column">
              <p>
                {user.first_name} {user.last_name}
              </p>
              <p>
                {user.address_street}, {user.address_number} -{" "}
                {user.address_district} - {user.address_postcode}
              </p>
              <p>
                {user.address_city} - {user.address_state}
              </p>
              <p>{user.address_complement}</p>
            </div>
          </CheckContainer>
        </>
      )}
      {!checked && (
        <>
          {user.address_postcode && <Divider />}
          <Form className="checkout-address-form">
            <Form.Group widths="equal">
              <Form.Field disabled={checked} required>
                <label>Nome:</label>
                <input
                  placeholder="Nome"
                  value={customAddress.firstName}
                  onChange={(e) =>
                    handleChangeCustomAddress("firstName", e.target.value)
                  }
                  required
                />
              </Form.Field>
              <Form.Field disabled={checked} required>
                <label>Sobrenome:</label>
                <input
                  placeholder="Sobrenome"
                  value={customAddress.lastName}
                  onChange={(e) =>
                    handleChangeCustomAddress("lastName", e.target.value)
                  }
                  required
                />
              </Form.Field>
            </Form.Group>
            <Form.Field width="8" disabled={checked} required>
              <label>CEP:</label>
              <div className="row align-items-center">
                <div className="row align-items-center">
                  <InputMask
                    mask="99999-999"
                    placeholder="00000-00"
                    value={customAddress.postcode}
                    onChange={(e) =>
                      handleChangeCustomAddress("postcode", e.target.value)
                    }
                    required
                  />
                </div>
                <Button
                  className="cart-toggle-icon"
                  icon="search icon"
                  onClick={handleSearchAddress}
                  disabled={loadingSearchAddress}
                  type="button"
                />
              </div>
              <a
                target="_blank"
                href="http://www.buscacep.correios.com.br/sistemas/buscacep/"
                style={{ marginTop: ".5em", fontSize: 12 }}
              >
                Não sei meu CEP
              </a>
            </Form.Field>
            <Form.Group>
              <Form.Field width={8} disabled={checked} required>
                <label>Rua:</label>
                <input
                  placeholder="Rua"
                  value={customAddress.street}
                  onChange={(e) =>
                    handleChangeCustomAddress("street", e.target.value)
                  }
                  required
                />
              </Form.Field>
              <Form.Field width={5} disabled={checked} required>
                <label>Bairro:</label>
                <input
                  placeholder="Bairro"
                  value={customAddress.district}
                  onChange={(e) =>
                    handleChangeCustomAddress("district", e.target.value)
                  }
                  required
                />
              </Form.Field>
              <Form.Field width={3} disabled={checked} required>
                <label>Número:</label>
                <input
                  placeholder="000"
                  value={customAddress.number}
                  onChange={(e) =>
                    handleChangeCustomAddress("number", e.target.value)
                  }
                  required
                />
              </Form.Field>
            </Form.Group>
            <Form.Group widths="equal">
              <Form.Field disabled={checked} required>
                <label>Cidade:</label>
                <input
                  placeholder="Cidade"
                  value={customAddress.city}
                  onChange={(e) =>
                    handleChangeCustomAddress("city", e.target.value)
                  }
                  required
                />
              </Form.Field>
              <Form.Field disabled={checked} required>
                <label>Estado:</label>
                <input
                  placeholder="UF"
                  value={customAddress.state}
                  onChange={(e) =>
                    handleChangeCustomAddress("state", e.target.value)
                  }
                  required
                />
              </Form.Field>
            </Form.Group>
            <Form.Field disabled={checked}>
              <label>Complemento:</label>
              <input
                placeholder="Complemento"
                value={customAddress.complement}
                onChange={(e) =>
                  handleChangeCustomAddress("complement", e.target.value)
                }
              />
            </Form.Field>
            {/* <Form.Group widths="equal">
              <Form.Field disabled={checked} required>
                <label >Telefone:</label>
                <input
                  placeholder="(xx) 9xxxx-xxxx"
                  value={phoneNumber}
                  onChange={(e) => setPhoneNumber(e.target.value)}
                  required
                />
              </Form.Field>
              <Form.Field disabled={checked}>
                <label >Celular:</label>
                <input
                  placeholder="(xx) 9xxxx-xxxx"
                  value={cellphoneNumber}
                  onChange={(e) => setCellphoneNumber(e.target.value)}
                />
              </Form.Field>
            </Form.Group>  */}
          </Form>
        </>
      )}
    </Segment>
  );
};

export default CheckoutAddress;
