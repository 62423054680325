import React from "react";
import { Segment, Card, Pagination } from "semantic-ui-react";
import { FaThLarge, FaTh } from "react-icons/fa";
import ProductCard from "../ProductCard";

function ProductListPage({
  setOrderBy,
  setItemsPerRow,
  itemsPerRow,
  products,
  selectedPage,
  numberOfPages,
  setSelectedPage,
}) {
  return (
    <Segment>
      <div className="row align-items-center justify-content-between">
        <select
          style={{ padding: 0, margin: "1em 0" }}
          className="ui selection dropdown order-by-select"
          onChange={(e) => setOrderBy(e.target.value)}
        >
          <option value={"-created_at"}>Mais recentes</option>
          <option value={"-value"}>Preço (Maior - Menor)</option>
          <option value={"value"}>Preço (Menor - Maior)</option>
        </select>
        <div className="row align-items-center product-list-row-option-container">
          <button
            className="product-list-row-option"
            onClick={() => setItemsPerRow(2)}
          >
            <FaThLarge
              className={
                itemsPerRow === 2
                  ? "product-list-row-option-icon-active"
                  : "product-list-row-option-icon"
              }
              size={18}
            />
          </button>
          <button
            className="product-list-row-option"
            onClick={() => setItemsPerRow(3)}
          >
            <FaTh
              className={
                itemsPerRow === 3
                  ? "product-list-row-option-icon-active"
                  : "product-list-row-option-icon"
              }
              size={18}
            />
          </button>
        </div>
      </div>
      {products.length === 0 && <p>Sem produtos para listagem</p>}
      <Card.Group
        itemsPerRow={itemsPerRow}
        stackable
        className="product-list-card-group"
      >
        {products.map((product) => (
          <ProductCard key={product.id} product={product} />
        ))}
      </Card.Group>
      <div className="horizontal-list mobile">
        {products.map((product) => (
          <ProductCard key={product.id} product={product} />
        ))}
      </div>
      <Pagination
        style={{ marginTop: "1.5em", backgroundColor: "transparent" }}
        boundaryRange={0}
        ellipsisItem={null}
        firstItem={null}
        lastItem={null}
        defaultActivePage={selectedPage}
        onPageChange={(e, { activePage }) => setSelectedPage(activePage)}
        totalPages={numberOfPages}
      />
    </Segment>
  );
}

export default ProductListPage;
