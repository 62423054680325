import React, { useState, useEffect } from "react";
import { Grid, Segment, Button, Divider } from "semantic-ui-react";
import InputMask from "react-input-mask";
import { Link } from "react-router-dom";
import CartList from "../../components/CartList";
import createNotification from "../../utils/notification";
import { calcularFrete } from "../../providers/correiosProvider";
import { useCart } from "../../contexts/cart";
import { useStore } from "../../contexts/store";
import { getProductVariation } from "../../providers/productProvider";
import "./styles.css";

function getSum(total, num) {
  return total + num;
}

export default function Cart() {
  const { store } = useStore();

  const { items, removeItem, clear, changeItemQuantity } = useCart();
  const [itemsStock, setItemsStock] = useState([]);
  const [postcode, setPostcode] = useState("");
  const [loadingCalc, setLoadingCalc] = useState(false);
  const [sedexShipping, setSedexShipping] = useState(null);
  const [pacShipping, setPacShipping] = useState(null);
  const [totalPrice, setTotalPrice] = useState("0");

  useEffect(() => {
    let tempPrice = items
                      .map((item) => item.price * item.count)
                      .reduce(getSum, 0)
                      .toFixed(2);
    setTotalPrice(tempPrice)
  }, [items]);

  useEffect(() => {
    getItemsStock();
  }, []);

  async function getItemsStock() {
    let stocks = [];

    for(let item of items){
      const result = await getProductVariation(item.id);

      if(result.error){
        stocks.push({
          id: item.id,
          stock: 0
        });
      }else {
        stocks.push({
          id: item.id,
          stock: result.stock
        });
      } 
    }
    
    setItemsStock(stocks);
  }

  function handleChangeQuantityItem(id, value) {
    return changeItemQuantity(id, value);
  }

  function handleRemoveItem(id) {
    return removeItem(id);
  }

  async function handleCalcShipping() {
    setLoadingCalc(true);

    const weightList = items.map(
      (item) => !item.freeShipping && item.weight * item.count
    );

    const reducer = (accumulator, currentValue) =>
      parseFloat(accumulator) + currentValue;

    const args = {
      origem: store.address_postcode,
      destino: postcode.replace(/\D/g, ""),
      peso: String(weightList.reduce(reducer).toFixed(2)),
      formato: 1,
      comprimento: 15,
      altura: 1,
      largura: 10,
      diametro: 0,
    };

    const response = await calcularFrete(args);

    if (response.error === true) {
      setLoadingCalc(false);
      return createNotification("error", "Erro", response.message);
    }
    setSedexShipping(response.sedex);
    setPacShipping(response.pac);
    setLoadingCalc(false);
  }

  return (
    <Grid columns={2} id="cart-container" stackable>
      <Grid.Row stretched>
        <Grid.Column width={10}>
          <Segment>
            <div className="cart-header-container row align-items-center">
              <h3>Seu carrinho</h3>
              <p>{`${items.length} ${items.length > 1 ? "itens" : "item"}`}</p>
            </div>
            {items.length === 0 && <h5>O carrinho está vazio</h5>}
            {items.length > 0 && itemsStock.length > 0 && (
              <CartList
                data={items}
                removeItem={handleRemoveItem}
                changeQuantityItem={handleChangeQuantityItem}
                itemsStock={itemsStock}
                cleanCart={clear}
              />
            )}
          </Segment>
        </Grid.Column>
        <Grid.Column width={6}>
          <Segment>
            <h3>Resumo do pedido</h3>
            <Segment className="cart-recipe">
              <strong>{`${items.length} ${
                items.length > 1 ? "produtos" : "produto"
              }`}</strong>
              <Divider />
              <div className="row align-items-center justify-content-between">
                <p>Subtotal:</p>
                <p>R$ {totalPrice}</p>
              </div>
              <div className="row align-items-center justify-content-between">
                <p>Parcelamento:</p>
                {items.length > 0 && <p>Até 6x s/ juros</p>}
              </div>
            </Segment>
            {items.length > 0 && (
              <>
                <Segment className="cart-recipe">
                  <strong>Calcular frete </strong>
                  <Divider />
                  <div
                    className={
                      loadingCalc ? "ui input loading shipping-calc-input" : "ui input action shipping-calc-input"
                    }
                  >
                    <InputMask
                      mask="99999-999"
                      value={postcode}
                      onChange={(e) => setPostcode(e.target.value)}
                      placeholder="00000-000"
                    />
                    <button
                      disabled={postcode.length < 8 || loadingCalc}
                      onClick={handleCalcShipping}
                      className={`ui button ${
                        postcode.length < 8 && "disabled"
                      }`}
                    >
                      {loadingCalc ? (
                        <i aria-hidden="true" className="spinner icon"></i>
                      ) : (
                        "Calcular"
                      )}
                    </button>
                  </div>
                  <br />
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="http://www.buscacep.correios.com.br/sistemas/buscacep/"
                  >
                    Não sei meu CEP
                  </a>

                  {sedexShipping && pacShipping && (
                    <table className="shipping-info-container">
                      <thead>
                        <tr>
                          <th>Serviço</th>
                          <th>Preço</th>
                          <th>Prazo</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <th>Sedex</th>
                          <th>{sedexShipping.Valor}</th>
                          <th>
                            {parseInt(sedexShipping.PrazoEntrega) > 1
                              ? `${sedexShipping.PrazoEntrega} dias`
                              : `${sedexShipping.PrazoEntrega} dia`}
                          </th>
                        </tr>
                        <tr>
                          <th>PAC</th>
                          <th>{pacShipping.Valor}</th>
                          <th>
                            {parseInt(pacShipping.PrazoEntrega) > 1
                              ? `${pacShipping.PrazoEntrega} dias`
                              : `${pacShipping.PrazoEntrega} dia`}
                          </th>
                        </tr>
                      </tbody>
                    </table>
                  )}
                </Segment>
                <Button
                  style={{ marginTop: "1em" }}
                  fluid
                  primary
                  as={Link}
                  to="/pagamento"
                >
                  Finalizar compra
                </Button>
              </>
            )}
          </Segment>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
}
