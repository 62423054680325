import React, { useEffect, useState, Fragment } from "react";
import {
  Segment,
  Header,
  Feed,
  Divider,
  Icon,
  Pagination,
  Rating,
} from "semantic-ui-react";
import { formatDistance } from "date-fns";
import pt from "date-fns/locale/pt-BR";
import createNotification from "../../utils/notification";
import { getEvaluations } from "../../providers/productProvider";

import "./styles.css";

function ProductEvaluationsList({ average, productId }) {
  const [evaluations, setEvaluations] = useState([]);
  const [count, setCount] = useState(0);
  const [selectedPage, setSelectedPage] = useState(1);
  const [numberOfPages, setNumberOfPages] = useState(1);

  async function getEvaluationsData(page = 1) {
    const result = await getEvaluations(productId, page);

    if (result.error) {
      return createNotification(
        "error",
        "Erro",
        "Ocorreu um erro ao tentar carregar a lista de avaliações"
      );
    }

    const tempNumberOfPages = Math.floor(result.count / 5);
    setNumberOfPages(tempNumberOfPages + 1);
    setCount(result.count);
    setEvaluations(result.results);
  }

  useEffect(() => {
    getEvaluationsData(selectedPage);
  }, [selectedPage]);

  return (
    <Segment style={{ width: "100%" }}>
      <div className="row align-items-center justify-content-between evaluations-list-header-container">
        <Header as="h5">Avaliações do produto</Header>
        <div className="row align-items-center">
          <h5>{average}</h5>
          <Icon name="star" color="yellow" size="small" />
          <p>({count} avaliações)</p>
        </div>
      </div>
      <Divider />
      <Feed>
        {evaluations.length === 0 && <strong>Produto sem avaliações</strong>}
        {evaluations.map((evaluation, index) => {
          let date = formatDistance(
            new Date(evaluation.published),
            new Date(),
            {
              locale: pt,
              addSuffix: true,
            }
          );

          if (date.startsWith("há cerca")) {
            date = "Hoje";
          }

          return (
            <Fragment key={evaluation.id}>
              <Feed.Event>
                <Feed.Content>
                  <Feed.Summary>
                    {evaluation.name}
                    <Feed.Date>{date}</Feed.Date>
                  </Feed.Summary>
                  <Feed.Extra>
                    <Rating
                      defaultRating={evaluation.grade}
                      maxRating={5}
                      disabled
                    />
                  </Feed.Extra>
                  <Feed.Extra text>{evaluation.commentary}</Feed.Extra>
                </Feed.Content>
              </Feed.Event>
              {index !== evaluations.length - 1 && <Divider />}
            </Fragment>
          );
        })}
      </Feed>
      {evaluations.length > 0 && (
        <Pagination
          style={{ marginTop: "1.5em" }}
          boundaryRange={0}
          ellipsisItem={null}
          firstItem={null}
          lastItem={null}
          defaultActivePage={selectedPage}
          onPageChange={(e, { activePage }) => setSelectedPage(activePage)}
          totalPages={numberOfPages}
        />
      )}
    </Segment>
  );
}

export default ProductEvaluationsList;
