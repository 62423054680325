import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Grid } from "semantic-ui-react";
import createNotification from "../../utils/notification";
import ProductListFilters from "../../components/ProductListFilters";
import ProductListPage from "../../components/ProductListPage";

import { getProductList } from "../../providers/productProvider";
import { PIXEL_TYPE_CHOICES, TrackPixelEvent } from "../../utils/facebookPixel";
import { useStore } from "../../contexts/store";

function Search() {
  const { store, pixelReady } = useStore();
  const params = useParams();

  const [products, setProducts] = useState([]);
  const [filtersSelections, setFiltersSelections] = useState(null);
  const [orderBy, setOrderBy] = useState("-created_at");
  const [itemsPerRow, setItemsPerRow] = useState(3);
  const [numberOfPages, setNumberOfPages] = useState(1);
  const [selectedPage, setSelectedPage] = useState(1);

  async function getProductListData(filters, page = 1) {
    const response = await getProductList(
      null,
      null,
      null,
      filters,
      orderBy,
      page,
      params.searchTerm === "" ? "" : params.searchTerm.split("-").join(" ")
    );

    if (response.error) {
      return createNotification(
        "error",
        "Erro",
        "Ocorreu um erro ao carregar a lista de produtos"
      );
    }

    const tempNumberOfPages = Math.floor(response.total_products / 20);
    setNumberOfPages(tempNumberOfPages + 1);
    return setProducts(response.page);
  }

  function cleanFilters() {
    let tempFiltersSelections = {};
    Object.keys(filtersSelections).map((key) => {
      tempFiltersSelections[key] = "Todos";
    });

    setFiltersSelections(tempFiltersSelections);
  }

  useEffect(() => {
    if(pixelReady) {
      TrackPixelEvent(store.events, PIXEL_TYPE_CHOICES.Search);
    }
  }, [])

  useEffect(() => {
    getProductListData(filtersSelections, selectedPage);
  }, [params, selectedPage, filtersSelections, orderBy]);

  return (
    <Grid columns={2} className="main-container" stackable>
      <Grid.Row>
        <Grid.Column width={5}>
          <ProductListFilters
            name={""}
            sector={1}
            filtersSelections={filtersSelections}
            setFiltersSelections={setFiltersSelections}
            isSearch={true}
            cleanFilters={cleanFilters}
          />
        </Grid.Column>
        <Grid.Column width={11}>
          <ProductListPage
            setOrderBy={setOrderBy}
            setItemsPerRow={setItemsPerRow}
            itemsPerRow={itemsPerRow}
            products={products}
            selectedPage={selectedPage}
            numberOfPages={numberOfPages}
            setSelectedPage={setSelectedPage}
          />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
}

export default Search;
