import React, { useState, useEffect } from "react";
import { Icon } from "semantic-ui-react";
import ReactImageMagnify from "react-image-magnify";
import ReactSlick from "react-slick";

import "./styles.css";

function ProductCarousel({ alt, srcList, rimProps, rsProps }) {
  const [dataSource, setDataSource] = useState([]);

  useEffect(() => {
    const tempDataSource = [];

    srcList.map((src) => {
      const imagesSet = [
        { src: src.small, setting: "500w" },
        { src: src.medium, setting: "1024w" },
        { src: src.large, setting: "1426w" },
      ]
        .map((item) => `${item.src} ${item.setting}`)
        .join(", ");

      return tempDataSource.push({
        srcSet: imagesSet,
        small: src.small,
        large: src.large,
      });
    });

    setDataSource(tempDataSource);
  }, [srcList]);

  return (
    <ReactSlick
      {...{
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        adaptiveHeight: true,
      }}
      {...rsProps}
    >
      {dataSource.map((src, index) => {
        let width = 1200;
        let height = 1800;

        return (
          <div key={index}>
            <ReactImageMagnify
              {...{
                enlargedImagePosition: "over",
                smallImage: {
                  alt: alt,
                  isFluidWidth: true,
                  src: src.small,
                  srcSet: src.srcSet,
                  sizes:
                    "(max-width: 360px) 100vw, (max-width: 1200px) 30vw, 360px",
                },
                largeImage: {
                  src: src.large,
                  width: width,
                  height: height,
                },
                enlargedImageStyle: {
                  objectFit: "cover",
                },
                imageStyle: {
                  objectFit: "contain",
                  maxHeight: 400,
                },
                lensStyle: { backgroundColor: "rgba(0,0,0,.6)" },
              }}
              {...rimProps}
            />
          </div>
        );
      })}
    </ReactSlick>
  );
}

export default ProductCarousel;
