import React, { useEffect, useState, Fragment } from "react";
import { Segment, Input, Button, Select } from "semantic-ui-react";
import { motion } from "framer-motion";
import fadeRight from "../../animations/fadeRight";
import { getFilters } from "../../providers/productProvider";
import createNotification from "../../utils/notification";

function ProductListFilters({
  name,
  sector,
  category,
  subcategory,
  isSearch,
  searchName,
  setSearchName,
  filtersSelections,
  setFiltersSelections,
  cleanFilters,
}) {
  const [filters, setFilters] = useState([]);
  const [options, setOptions] = useState([]);

  useEffect(() => {
    async function getFiltersData() {
      const result = await getFilters(sector, category, subcategory);

      if (result.error) {
        return createNotification(
          "error",
          "Erro",
          "Ocorreu um erro ao carregar a lista de filtros"
        );
      }

      const tempOptions = [];
      const tempFiltersSelections = {};
      result.map((filter) => {
        tempOptions.push(handleOptions(filter.options));
        tempFiltersSelections[filter.title] = "Todos";
      });

      setFiltersSelections(tempFiltersSelections);
      setOptions(tempOptions);
      setFilters(result);
    }

    getFiltersData();
  }, [sector, category, subcategory]);

  function handleOptions(options) {
    let tempList = options.map((option) => {
      return { key: option.id, value: option.title, text: option.title };
    });

    tempList = [{ key: 0, value: "Todos", text: "Todos" }, ...tempList];

    return tempList;
  }

  function handleChangeFilter(key, value) {
    const tempFiltersSelections = { ...filtersSelections, [key]: value };
    setFiltersSelections(tempFiltersSelections);
  }

  return (
    <Segment
      as={motion.div}
      variants={fadeRight}
      initial="faderight"
      animate="normal"
      exit="exit"
    >
      <h2>{name}</h2>
      <h3 style={{ color: "var(--primary-text-color)", marginTop: ".2em" }}>
        Filtros
      </h3>
      {!isSearch && (
        <>
          <label>Pesquisar:</label>
          <Input
            fluid
            value={searchName}
            onChange={(e, { value }) => setSearchName(value)}
            style={{ margin: ".5em 0 1em 0" }}
            placeholder="Pesquisar..."
          />
        </>
      )}
      {filters.map((filter, index) => (
        <Fragment key={filter.id}>
          <label>{filter.title}:</label>
          <br />
          <Select
            fluid
            value={filtersSelections[filter.title]}
            onChange={(e, { value }) => handleChangeFilter(filter.title, value)}
            className="filter-select"
            options={options[index]}
          />
        </Fragment>
      ))}
      <Button onClick={cleanFilters}>Limpar filtros</Button>
    </Segment>
  );
}

export default ProductListFilters;
