import provider from "./config";

export async function check(code) {
  try {
    const response = await provider.get("/coupon/check", {
        params: {
            code
        },
    });
    return response.data;
  } catch (err) {
      return {error: true, message: "Cupom inválido"}
    }
}