import React, {useState, useEffect} from 'react';
import CookieConsent from "react-cookie-consent";
import {Link} from "react-router-dom"
import {useStore} from "../../contexts/store";

function CookieSnackbar() {
    const {store} = useStore();

    const [cookieName, setCookieName] = useState("");

    useEffect(() => {
        const {name} = store;
        if(name)
            setCookieName(`${name.replace(" ", "").toLowerCase()}CookieConsent`);
    }, [store]);

    return (
        cookieName.length > 0 ?
            <CookieConsent
                location="bottom"
                buttonText="Aceitar"
                cookieName={cookieName}
                expires={365}
            >
                Utilizamos cookies essenciais e tecnologias semelhantes de acordo com a nossa <Link to="politica">Política de Privacidade</Link> e, ao continuar navegando, você concorda com estas condições.
            </CookieConsent>
        : <></>
    );
}

export default CookieSnackbar;