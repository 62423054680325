import React, {useEffect} from "react";
import { FaPlus, FaMinus } from "react-icons/fa";
import { Label } from "semantic-ui-react";

import "./styles.css";

export default function QuantityPicker({ onChange, value, maxValue = 0 }) {

  useEffect(() => {
    if(value >= maxValue){
      onChange(maxValue);
    }
  }, [maxValue]);

  function handleChangeValue(valueToSum) {
    if (value >= 1)
      if (valueToSum === -1 && value === 1) return;
      else if (valueToSum === 1 && value >= maxValue) return;
      else return onChange(value + valueToSum);
  }

  return (
    <div className="column">
      <strong style={{ fontSize: 12 }}>Quantidade</strong>
      <div className="row justify-content-between align-items-center quantity-picker-container">
        <button
          onClick={() => handleChangeValue(-1)}
          className="quantity-picker-button"
        >
          <FaMinus size={10} />
        </button>
        <p className="quantity-picker-value">{value}</p>
        <button
          onClick={() => handleChangeValue(1)}
          className="quantity-picker-button"
        >
          <FaPlus size={10} />
        </button>
      </div>
    </div>
  );
}
