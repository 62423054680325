import React, { useState } from "react";
import {
  Segment,
  Header,
  Form,
  Input,
  Rating,
  TextArea,
  Button,
} from "semantic-ui-react";
import { evaluateProduct } from "../../providers/productProvider";
import createNotification from "../../utils/notification";
import { validateEmail } from "../../utils/validateForm";

export default function ProductEvaluation({ loading, setLoading, productId }) {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [commentary, setCommentary] = useState("");
  const [grade, setGrade] = useState(1);

  async function handleOnSend(e) {
    e.preventDefault();
    setLoading(true);

    if (!validateEmail(email)) {
      setLoading(false);
      return createNotification("error", "Erro", "Email inválido");
    }

    const payload = {
      nome: name,
      codigo: email,
      observacao: commentary,
      quantidade: grade,
      idRecurso: productId,
      idClasse: '637e57629aa620518fbad632',
    };

    const result = await evaluateProduct(payload);

    if (result.error) {
      setLoading(false);
      return createNotification(
        "error",
        "Erro",
        "Ocorreu um erro ao tentar cadastrar sua avaliação"
      );
    }

    setName("");
    setEmail("");
    setGrade(1);
    setCommentary("");

    createNotification("success", "Sucesso", "Avaliação enviada com sucesso");
    setLoading(false);
  }

  return (
    <Segment style={{ width: "100%", minHeight: 420 }}>
      <Header as="h5">Avaliar o produto</Header>
      <Form onSubmit={handleOnSend}>
        <Form.Field>
          <label>Avaliação: </label>
          <Rating
            icon="star"
            rating={grade}
            maxRating={5}
            onRate={(e, { rating }) => setGrade(rating)}
          />
        </Form.Field>
        <Form.Field required>
          <label>Nome: </label>
          <Input
            fluid
            placeholder="Nome"
            value={name}
            onChange={(e, { value }) => setName(value)}
            required
          />
        </Form.Field>
        <Form.Field required>
          <label>Email: </label>
          <Input
            fluid
            placeholder="Email"
            value={email}
            onChange={(e, { value }) => setEmail(value)}
            required
          />
        </Form.Field>
        <Form.Field required>
          <label>Comentário: </label>
          <TextArea
            placeholder="Comentário"
            value={commentary}
            onChange={(e, { value }) => setCommentary(value)}
            required
          />
        </Form.Field>
        <Button floated="right" primary disabled={loading}>
          {loading ? <i aria-hidden="true" class="spinner icon"></i> : "Enviar"}
        </Button>
      </Form>
    </Segment>
  );
}
