import React, { useState } from "react";
import { Grid, Menu, Icon, Segment } from "semantic-ui-react";
import { useParams, useHistory } from "react-router-dom";
import { useAuth } from "../../contexts/auth";
import ProfileEditing from "./ProfileEditing";
import AddressEditing from "./AddressEditing";
import Orders from "./Orders";

export default function Profile() {
  const { signOut } = useAuth();
  const { section, id } = useParams();
  const history = useHistory();
  const [activeItem, setActiveItem] = useState(section);

  function handleItemClick(e, { name }) {
    setActiveItem(name.toLowerCase().replace(" ", "-").replace("ç", "c"));
    return history.push(`/perfil/${name.toLowerCase().replace(" ", "-").replace("ç", "c")}`);
  }

  function handleActiveComponent() {
    switch (activeItem) {
      default:
        break;
      case "meus-dados":
        return <ProfileEditing />;
      case "endereco":
        return <AddressEditing />;
      case "meus-pedidos":
        return <Orders id={id ? id : null} />;
    }
  }

  return (
    <Grid stackable className="profile-container">
      <Grid.Column width={4} className="menu-container">
        <Menu fluid vertical>
          <Menu.Item
            name="Meus Dados"
            active={activeItem === "meus-dados"}
            onClick={handleItemClick}
          >
            <Icon name="user" />
            Meus Dados
          </Menu.Item>
          <Menu.Item
            name="Endereço"
            active={activeItem === "endereco"}
            onClick={handleItemClick}
          >
            <Icon name="home" />
            Endereço
          </Menu.Item>
          <Menu.Item
            name="Meus Pedidos"
            active={activeItem === "meus-pedidos"}
            onClick={handleItemClick}
          >
            <Icon name="shipping fast" />
            Meus Pedidos
          </Menu.Item>
          <Menu.Item style={{ color: "red" }} name="Sair" onClick={signOut}>
            <Icon name="sign-out" />
            Sair
          </Menu.Item>
        </Menu>
      </Grid.Column>

      <Grid.Column width={12} className="profile-container-content">
        <Segment>{handleActiveComponent()}</Segment>
      </Grid.Column>
    </Grid>
  );
}
