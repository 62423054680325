import React from "react";
import {Link} from "react-router-dom";
import { Carousel } from "react-responsive-carousel";

const NormalBanner = ({banner}) => (
  <div className="carousel-image-container">
    <img src={banner.image} alt="banner" />
  </div>
);

const LinkBanner = ({banner}) => {

  let url = "";
  let linkTo = banner.product_base ? "PRODUTO" : banner.subcategory ? "SUBCATEGORIA" : banner.category ? "CATEGORIA" : "SETOR";

  switch(linkTo){
    default: break;
    case "PRODUTO":
      url = `/produto/${banner.product_base}`;
      break;
    case "SETOR":
      url = `/setor/${banner.sector}`;
      break;
    case "CATEGORIA":
      url = `/setor/${banner.sector}/categoria/${banner.category}`;
      break;
    case "SUBCATEGORIA":
      url = `/setor/${banner.sector}/categoria/${banner.category}/subcategoria/${banner.subcategory}`;
      break;
  }

  return (
    <Link to={url}>
      <div className="carousel-image-container">
        <img src={banner.image} alt="banner" />
      </div>
    </Link>
  )
}

const HomepageHeading = ({ banners }) => {

  const renderBanner = (banner) => {
    if(banner.product_base || banner.sector || banner.category || banner.subcategory){
      return <LinkBanner banner={banner} key={banner.id} />
    }else{
      return <NormalBanner banner={banner} key={banner.id} />
    }

  }

  return (
    <>
      <Carousel
        infiniteLoop={true}
        autoPlay={true}
        showStatus={false}
        showThumbs={false}
      >
        {banners.map((banner) => renderBanner(banner))}
      </Carousel>
    </>
  );
};

export default HomepageHeading;
