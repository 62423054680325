import React, { useState, useEffect } from "react";
import { Segment, Grid, Image, Icon, Divider } from "semantic-ui-react";
import { Link } from "react-router-dom";

//format dates
import { parseISO, format } from "date-fns";
import ptbr from "date-fns/locale/pt-BR";

import createNotification from "../../utils/notification";
import OrderDetail from "../../components/OrderDetail";
import { getUserOrders } from "../../providers/authProvider";
import { useAuth } from "../../contexts/auth";
import "./styles.css";

const orderDeliveryStatus = {
  "AGUARDANDO APROVAÇÃO": {
    name: "Pedido realizado",
    icon: "shopping cart",
  },
  "CANCELADO": {
    name: "Pedido Cancelado",
    icon: "shopping cart",
  },
  REQUISITADO: {
    name: "Pedido realizado",
    icon: "shopping cart",
  },
  CONFIRMADO: {
    name: "Pagamento confirmado",
    icon: "check",
  },
  "EM SEPARAÇÃO": {
    name: "Em separação",
    icon: "box",
  },
  "EM TRANSPORTE": {
    name: "Em transporte",
    icon: "shipping fast",
  },
  ENTREGUE: {
    name: "Entregue",
    icon: "home",
  },
};

const orderStoreStatus = {
  "AGUARDANDO APROVAÇÃO": {
    name: "Aguardando aprovação",
    icon: "shopping cart",
  },
  "CANCELADO": {
    name: "Pedido cancelado",
    icon: "shopping cart",
  },
  REQUISITADO: {
    name: "Pedido realizado",
    icon: "shopping cart",
  },
  CONFIRMADO: {
    name: "Pagamento confirmado",
    icon: "check",
  },
  "AGUARDANDO RETIRADA": {
    name: "Aguardando retirada",
    icon: "box",
  },
  ENTREGUE: {
    name: "Entregue",
    icon: "home",
  },
};

const OrderItem = ({ order, hasDivider }) => {
  const previewProduct = order.list_itens[0].product;
  
  return (
    <>
      <Grid stackable>
        <Grid.Row stretched columns={3} divided style={{ paddingTop: 0 }}>
          <Grid.Column width={5}>
            <Grid.Row className="order-item-container">
              <Image
                src={previewProduct.product_base.image}
                alt={previewProduct.name}
              />
              <div className="column">
                <strong>
                  {order.list_itens[0].quantity}x {previewProduct.name}
                </strong>
                {order.list_itens.length - 1 > 0 && (
                  <p>
                    (+
                    {order.list_itens.length - 1 > 1
                      ? `${order.list_itens.length - 1} itens`
                      : `${order.list_itens.length - 1} item`}
                    )
                  </p>
                )}
              </div>
            </Grid.Row>
          </Grid.Column>
          <Grid.Column width={5} className="order-status-container">
            <strong style={{ fontSize: 12 }}>Status do pedido</strong>
            <Segment
              className="row align-items-center"
              style={{ marginTop: ".5em" }}
            >
              <Icon
                name={
                  order.delivery_type === "RETIRAR NA LOJA"
                    ? orderStoreStatus[order.status].icon
                    : orderDeliveryStatus[order.status].icon
                }
                size="large"
              />
              <p
                style={{
                  marginLeft: 8,
                  fontSize: 12,
                  fontWeight: "500",
                }}
              >
                {order.delivery_type === "RETIRAR NA LOJA"
                  ? orderStoreStatus[order.status].name
                  : orderDeliveryStatus[order.status].name}
              </p>
            </Segment>
          </Grid.Column>
          <Grid.Column width={6} className="order-info-container">
            <strong>Resumo do pedido</strong>
            <div className="row align-items-center justify-content-between">
              <strong>Pedido: </strong>
              <p>{order.code}</p>
            </div>
            <div className="row align-items-center justify-content-between">
              <strong>Data do pedido: </strong>
              <p>{format(parseISO(order.created_at), "dd/MM/yyyy")}</p>
            </div>
            <div className="row align-items-center justify-content-between">
              <strong>Valor total: </strong>
              <p>R${parseFloat(order.total_amount).toFixed(2)}</p>
            </div>
            <Link to={`/perfil/meus-pedidos/${order.code}`}>Ver detalhes</Link>
          </Grid.Column>
        </Grid.Row>
      </Grid>
      {hasDivider && <Divider />}
    </>
  );
};

export default function InProgressOrders({ id }) {
  const { user } = useAuth();
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function getOrdersData() {
      const result = await getUserOrders(user.id, user.token);

      if (result.error) {
        setLoading(false);
        return createNotification(
          "error",
          "Erro",
          "Ocorreu um erro ao carregar a sua lista de pedidos"
        );
      }

      setOrders(result);
      setLoading(false);
    }

    getOrdersData();
  }, []);

  if (!loading) {
    return (
      <>
        {id ? (
          <OrderDetail
            key={id}
            order={orders.filter((order) => order.code === id)[0]}
          />
        ) : (
          <>
            {orders.length === 0 && <p>Sem pedidos</p>}
            {orders.map((order, index) => (
              <OrderItem
                key={order.id}
                order={order}
                hasDivider={index !== orders.length - 1}
              />
            ))}
          </>
        )}
      </>
    );
  } else {
    return <p>Carregando</p>;
  }
}
