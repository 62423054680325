import provider from "./config";

export async function getStore() {
  try {
    const response = await provider.get("/entidades", {
      params: {
        classe: 'Estabelecimentos',
      }
    });

    const baseStore = response.data[0];

    const store = {
      "id": baseStore.id,
      "name": baseStore.nome,
      "logo": baseStore.foto,
      "pickup": false,
      "credit_pay": true,
      "brazilian_boleto_pay": true,
      "pix_pay": false,
      "favicon": baseStore.foto,
      "phone": baseStore.telefone,
      "email": baseStore.email,
      "whatsapp_number": baseStore.telefone,
      "facebook": baseStore.facebook || null,
      "instagram": baseStore.instagram || null,
      "twitter": baseStore.twitter || null,
      "description": baseStore.observacao,
      "address_street": baseStore.cep === '00000-000' ? 'On-line' : baseStore.endereco,
      "address_state": baseStore.cep === '00000-000' ? '' : baseStore.UF.nome,
      "address_postcode": baseStore.cep,
      "address_city": baseStore.cep === '00000-000' ? '' : baseStore.Cidade.nome,
      "address_district": baseStore.cep === '00000-000' ? '' : baseStore.bairro,
      "address_number": baseStore.cep === '00000-000' ? '' : baseStore.numeroEndereco,
      "address_complement": baseStore.cep === '00000-000' ? '' : baseStore.complemento,
      "events": [],
    }

    return store;
  } catch (err) {
    return { error: true, message: err.message };
  }
}

export async function sendContactMessage(payload) {
  try {
    const response = await provider.post("/tabela/", payload);
    return response.data;
  } catch (err) {
    return { error: true, message: err.message };
  }
}
