import React from 'react';

import './styles.css';

const Develop = () => {
  return <div className='developed-info'>
    <p>Developed by <a className="developed-link" href="https://devari.com.br/" target="_blank">Devari Tecnologia</a></p>
  </div>
}

export default Develop;