import React, { useState, useEffect } from "react";
import { Grid, Header, Form, Segment, Button } from "semantic-ui-react";
import {useLocation} from "react-router-dom";
import { useAuth } from "../../contexts/auth";
import createNotification from "../../utils/notification";
import InputMask from "react-input-mask";
import * as validation from "../../utils/validateForm";
import { PIXEL_TYPE_CHOICES, TrackPixelEvent } from "../../utils/facebookPixel";
import { useStore } from "../../contexts/store";

export default function Register() {
  const { store, pixelReady } = useStore();
  const { signUp } = useAuth();
  const location = useLocation();

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [gender, setGender] = useState("");
  const [cpf, setCpf] = useState("");
  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [birthDay, setBirthDay] = useState("");
  const [from, setFrom] = useState(null);

  // useEffect(() => {
    // if(pixelReady) {
    //   TrackPixelEvent(store.events, PIXEL_TYPE_CHOICES.CompleteRegistration);
    // }
  // }, [])
  
  useEffect(() => {
    if(location.state && location.state.from){
      setFrom(location.state.from);
    }
  }, [location]);

  async function handleSignUp() {
    if (!firstName || !lastName || !gender || !cpf.replace(/\D/g, ""))
      return createNotification(
        "error",
        "Erro",
        "Preencha todos os campos obrigatórios"
      );

    if (!validation.validateEmail(email))
      return createNotification("error", "Erro", "Email inválido");

    if (!validation.validatePassword(password, confirmPassword))
      return createNotification("error", "Erro", "As senhas estão diferentes");

    if (!validation.validateCPF(cpf.replace(/\D/g, "")))
      return createNotification("error", "Erro", "CPF inválido");

    let payload = {
      nome: `${firstName} ${lastName}`,
      email,
      senha: password,
      cpfCnpj: cpf.replace(/\D/g, ""),
      genero: gender,
      telefone: phone,
      comUserGroup: true,
      idClasse: '61cd2730ad47e0a02eab67d8'
    };

    if(birthDay){
      payload = {...payload, dataNascimento: new Date(birthDay),};
    }

    signUp(payload, from);
  }

  return (
    <>
      <Header as="h2" textAlign="center" style={{ marginTop: 48 }}>
        Cadastre-se
      </Header>
      <Grid
        textAlign="center"
        style={{ padding: "1em 4em 3em 4em" }}
        columns={2}
        stackable
      >
        <Grid.Row stretched>
          <Grid.Column width={6}>
            <Segment
              style={{ backgroundColor: "var(--bg-gray)", textAlign: "left" }}
            >
              <Form>
                <Form.Group widths="equal">
                  <Form.Field required>
                    <label style={{ fontWeight: "500" }}>Nome:</label>
                    <input
                      placeholder="Nome"
                      value={firstName}
                      onChange={(e) => setFirstName(e.target.value)}
                      required
                    />
                  </Form.Field>
                  <Form.Field required>
                    <label style={{ fontWeight: "500" }}>Sobrenome:</label>
                    <input
                      placeholder="Sobrenome"
                      value={lastName}
                      onChange={(e) => setLastName(e.target.value)}
                      required
                    />
                  </Form.Field>
                </Form.Group>
                <Form.Field required>
                  <label style={{ fontWeight: "500" }}>Email:</label>
                  <input
                    placeholder="Email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  />
                </Form.Field>
                <Form.Field required>
                  <label style={{ fontWeight: "500" }}>Sexo:</label>
                  <Form.Select
                    required
                    fluid
                    placeholder="Sexo"
                    onChange={(e, { value }) => setGender(value)}
                    options={[
                      { text: "Masculino", value: "Masculino" },
                      { text: "Feminino", value: "Feminino" },
                      { text: "Prefiro não declarar", value: "Outro" },
                    ]}
                  />
                </Form.Field>
                <Form.Field required>
                  <label style={{ fontWeight: "500" }}>CPF:</label>
                  <div className="ui input">
                    <InputMask
                      mask="999999999-99"
                      value={cpf}
                      onChange={(e) => setCpf(e.target.value)}
                      placeholder="xxxxxxxxx-xx"
                    />
                  </div>
                </Form.Field>
                <Form.Field>
                  <label style={{ fontWeight: "500" }}>
                    Data de nascimento:
                  </label>
                  <input
                    type="date"
                    // max={`${new Date().getFullYear() - 18}-01-01`}
                    onChange={(e) => setBirthDay(e.target.value)}
                    value={birthDay}
                  />
                </Form.Field>
                <Form.Field required>
                  <label style={{ fontWeight: "500" }}>Telefone:</label>
                  <div className="ui input">
                    <InputMask
                      mask="(99) 99999-9999"
                      value={phone}
                      onChange={(e) => setPhone(e.target.value)}
                      placeholder="(xx) 9xxxx-xxxx"
                      required
                    />
                  </div>
                </Form.Field>
                <Form.Group widths="equal">
                  <Form.Field required>
                    <label style={{ fontWeight: "500" }}>Senha:</label>
                    <input
                      type="password"
                      placeholder="Senha"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      required
                    />
                  </Form.Field>
                  <Form.Field required>
                    <label style={{ fontWeight: "500" }}>
                      Confirmar senha:
                    </label>
                    <input
                      type="password"
                      placeholder="Confirmar senha"
                      value={confirmPassword}
                      onChange={(e) => setConfirmPassword(e.target.value)}
                      required
                    />
                  </Form.Field>
                </Form.Group>
                <Form.Field required>
                  <label
                    style={{ fontWeight: "300", fontSize: 12, marginBottom: 0 }}
                  >
                    São obrigatórios
                  </label>
                </Form.Field>
                <Button primary fluid onClick={handleSignUp}>
                  Criar conta
                </Button>
              </Form>
            </Segment>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </>
  );
}
