import React from "react";
import { Step } from "semantic-ui-react";

const orderDeliveryStatus = {
  "AGUARDANDO APROVAÇÃO": 0,
  "CANCELADO": 0,
  REQUISITADO: 0,
  CONFIRMADO: 1,
  "EM SEPARAÇÃO": 2,
  "EM TRANSPORTE": 3,
  ENTREGUE: 4,
};

const orderStoreStatus = {
  "AGUARDANDO APROVAÇÃO": 0,
  "CANCELADO": 0,
  REQUISITADO: 0,
  CONFIRMADO: 1,
  "AGUARDANDO RETIRADA": 2,
  ENTREGUE: 3,
};

export default function OrderStep({ status, type }) {
  return (
    <Step.Group stackable="tablet" widths={5} ordered size="mini">
      <Step
        active={status === "REQUISITADO"}
        completed={
          type === "RETIRAR NA LOJA"
            ? orderStoreStatus[status.toUpperCase()] > 0
            : orderDeliveryStatus[status.toUpperCase()] > 0
        }
      >
        <Step.Content>
          <Step.Title>Pedido {status === 'CANCELADO' ? "cancelado" : "realizado"}</Step.Title>
        </Step.Content>
      </Step>
      <Step
        active={status === "CONFIRMADO"}
        completed={
          type === "RETIRAR NA LOJA"
            ? orderStoreStatus[status.toUpperCase()] > 1
            : orderDeliveryStatus[status.toUpperCase()] > 1
        }
        disabled={
          type === "RETIRAR NA LOJA"
            ? orderStoreStatus[status.toUpperCase()] < 1
            : orderDeliveryStatus[status.toUpperCase()] < 1
        }
      >
        <Step.Content>
          <Step.Title>Pagamento Confirmado</Step.Title>
        </Step.Content>
      </Step>
      <Step
        active={status === "EM SEPARAÇÃO" || status === "AGUARDANDO RETIRADA"}
        completed={
          type === "RETIRAR NA LOJA"
            ? orderStoreStatus[status.toUpperCase()] > 2
            : orderDeliveryStatus[status.toUpperCase()] > 2
        }
        disabled={
          type === "RETIRAR NA LOJA"
            ? orderStoreStatus[status.toUpperCase()] < 2
            : orderDeliveryStatus[status.toUpperCase()] < 2
        }
      >
        <Step.Content>
          <Step.Title>
            {type === "RETIRAR NA LOJA"
              ? "Aguardando Retirada"
              : "Em separação"}
          </Step.Title>
        </Step.Content>
      </Step>
      {type !== "RETIRAR NA LOJA" && (
        <Step
          active={status === "EM TRANSPORTE"}
          completed={orderDeliveryStatus[status.toUpperCase()] > 3}
          disabled={orderDeliveryStatus[status.toUpperCase()] < 3}
        >
          <Step.Content>
            <Step.Title>Em transporte</Step.Title>
          </Step.Content>
        </Step>
      )}
      <Step
        active={status === "ENTREGUE"}
        completed={
          type === "RETIRAR NA LOJA"
            ? orderStoreStatus[status.toUpperCase()] > 2
            : orderDeliveryStatus[status.toUpperCase()] > 3
        }
        disabled={
          type === "RETIRAR NA LOJA"
            ? orderStoreStatus[status.toUpperCase()] < 2
            : orderDeliveryStatus[status.toUpperCase()] < 3
        }
      >
        <Step.Content>
          <Step.Title>Pedido Entregue</Step.Title>
        </Step.Content>
      </Step>
    </Step.Group>
  );
}
