import ReactPixel from 'react-facebook-pixel';

export const PIXEL_TYPE_CHOICES = {
  AddPaymentInfo: "AddPaymentInfo",
  AddToCart: "AddToCart",
  CompleteRegistration: "CompleteRegistration",
  Contact: "Contact",
  InitiateCheckout: "InitiateCheckout",
  Search: "Search",
  ViewContent: "ViewContent",
  Purchase: "Purchase"
};

export const TrackPixelEvent = (eventList, event) => {
  // const hasEvent = eventList.find(item => item.px_type === event);
  // if(hasEvent && hasEvent.is_active) {
  ReactPixel.track(event);
  // }
}