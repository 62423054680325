import React, { useState } from "react";
import { Form } from "semantic-ui-react";
import InputMask from "react-input-mask";
import { useAuth } from "../../contexts/auth";
import createNotification from "../../utils/notification";
import {
  validateEmail,
  validateCPF,
  validatePassword,
} from "../../utils/validateForm";

export default function Profile() {
  const { user, updateUser } = useAuth();

  const [firstName, setFirstName] = useState(user.first_name);
  const [lastName, setLastName] = useState(user.last_name);
  const [gender, setGender] = useState(user.gender);
  const [email, setEmail] = useState(user.email);
  const [cpf_cnpj, setCPF_CNPJ] = useState(user.cpf_cnpj);
  const [birthDay, setBirthDay] = useState(user.birthdate);
  const [phoneNumber, setPhoneNumber] = useState(user.phone);
  const [cellphone, setCellphone] = useState(user.cell_phone);
  const [enableChangePassword, setEnableChangePassword] = useState(false);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [loading, setLoading] = useState(false);

  async function handleUpdateProfile(e) {
    e.preventDefault();
    setLoading(true);

    let cpf = cpf_cnpj.replace(/\D/g, "");

    if (!validateEmail(email)) {
      setLoading(false);
      return createNotification("error", "Erro", "Email inválido");
    }

    if (!validateCPF(cpf)) {
      setLoading(false);
      return createNotification("error", "Erro", "CPF inválido");
    }

    let payload = {
      first_name: firstName,
      last_name: lastName,
      email,
      gender,
      cpf_cnpj: cpf,
      phone: phoneNumber,
      cell_phone: cellphone,
    };

    if (password.length > 0) {
      if (!validatePassword(password, confirmPassword)) {
        setLoading(false);
        return createNotification(
          "error",
          "Erro",
          "As senhas estão diferentes"
        );
      }

      payload = { ...payload, password };
    }

    if(birthDay){
      payload = {...payload, birthdate: birthDay,};
    }else {
      payload = {...payload, birthdate: null,};
    }

    const result = await updateUser(user.id, payload);

    if (!result) {
      setLoading(false);
      return createNotification(
        "error",
        "Erro",
        "Ocorreu um erro ao tentar atualizar seu perfil"
      );
    }

    setLoading(false);
    setEnableChangePassword(false);
    return createNotification(
      "success",
      "Sucesso",
      "Perfil atualizado com sucesso"
    );
  }

  return (
    <>
      <h3>Meus Dados</h3>
      <Form
        id="update-profile-form"
        onSubmit={handleUpdateProfile}
        style={{ padding: "0 1em" }}
      >
        <Form.Group widths="equal">
          <Form.Field required>
            <label style={{ fontWeight: "500" }}>Nome:</label>
            <input
              required
              placeholder="Nome"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
            />
          </Form.Field>
          <Form.Field required>
            <label style={{ fontWeight: "500" }}>Sobrenome:</label>
            <input
              required
              placeholder="Sobrenome"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
            />
          </Form.Field>
        </Form.Group>
        <Form.Field required>
          <label style={{ fontWeight: "500" }}>Email:</label>
          <input
            required
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </Form.Field>
        <Form.Field required>
          <label style={{ fontWeight: "500" }}>Sexo:</label>
          <Form.Select
            required
            fluid
            placeholder="Sexo"
            options={[
              { text: "Masculino", value: "Masculino" },
              { text: "Feminino", value: "Feminino" },
              { text: "Prefiro não declarar", value: "Outro" },
            ]}
            defaultValue={gender}
            onChange={(e, { value }) => setGender(value)}
          />
        </Form.Field>
        <Form.Field required>
          <label style={{ fontWeight: "500" }}>CPF:</label>
          <InputMask
            mask="999999999-99"
            required
            placeholder="CPF"
            value={cpf_cnpj}
            onChange={(e) => setCPF_CNPJ(e.target.value)}
          />
        </Form.Field>
        <Form.Field>
          <label style={{ fontWeight: "500" }}>Data de nascimento:</label>
          <input
            type="date"
            // max={`${new Date().getFullYear() - 18}-01-01`}
            onChange={(e) => setBirthDay(e.target.value)}
            value={birthDay}
          />
        </Form.Field>
        <Form.Group widths="equal">
          <Form.Field required>
            <label style={{ fontWeight: "500" }}>Telefone:</label>
            <InputMask
              required
              mask="(99) 99999-9999"
              placeholder="(xx) xxxxx-xxxx"
              value={phoneNumber}
              onChange={(e) => setPhoneNumber(e.target.value)}
            />
          </Form.Field>
          <Form.Field>
            <label style={{ fontWeight: "500" }}>Celular:</label>
            <InputMask
              mask="(99) 99999-9999"
              placeholder="(xx) xxxxx-xxxx"
              value={cellphone}
              onChange={(e) => setCellphone(e.target.value)}
            />
          </Form.Field>
        </Form.Group>
        {enableChangePassword && (
          <Form.Group widths="equal">
            <Form.Field required>
              <label style={{ fontWeight: "500" }}>Senha:</label>
              <input
                required
                type="password"
                placeholder="Digite sua nova senha"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </Form.Field>
            <Form.Field required>
              <label style={{ fontWeight: "500" }}>Confirmar senha:</label>
              <input
                required
                type="password"
                placeholder="Confirme sua nova senha"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
              />
            </Form.Field>
          </Form.Group>
        )}
        <Form.Field>
          <button
            type="button"
            className="ui basic fluid button"
            disabled={loading}
            onClick={() => setEnableChangePassword(!enableChangePassword)}
          >
            Alterar senha
          </button>
          <button
            form="update-profile-form"
            type="submit"
            className="ui fluid primary button"
            disabled={loading}
            style={{ marginTop: "1em" }}
          >
            {loading ? (
              <i aria-hidden="true" class="spinner icon"></i>
            ) : (
              "Salvar alterações"
            )}
          </button>
        </Form.Field>
      </Form>
    </>
  );
}
