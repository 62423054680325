import provider from "./config";

export async function getBanners() {
  try {
    const response = await provider.get("/tabela?situacao=Ativo&classe=Banners");
    const data = response.data;

    const banners = data.map((banner) => {
      const classes = banner.observacao.split(' ');
      return {
        "id": banner.id,
        "name": banner.nome,
        "image": banner.foto,
        "image_small": banner.foto,
        "image_medium": banner.foto,
        "product_base": banner.idRecurso,
        "sector": classes[0],
        "category": classes[1] || null,
        "subcategory": classes[2] || null,
        "is_active": true,
        "created_at": banner.criadoEm,
      }
    });
    return banners;
  } catch (err) {
    return { error: true, message: err.message };
  }
}

export async function getFAQ() {
  try {
    const response = await provider.get("/tabela", {
      params: {
        classe: 'FAQ',
      }
    });

    const data = response.data;

    const faq = data.map((item) => {
      return {
        "id": item.id,
        "question": item.nome,
        "answer": item.descricao,
        "created_at": item.criadoEm,
        "updated_at": item.atualizadoEm,
        "topic": null
      };
    });

    return faq;
  } catch (err) {
    return { error: true, message: err.message };
  }
}
