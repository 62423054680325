import React, { Component } from "react";
import {
  Responsive,
  Visibility,
  Segment,
  Menu,
  Container,
  Dropdown,
  Button,
  Input,
} from "semantic-ui-react";
import { motion } from "framer-motion";
import { Link, withRouter } from "react-router-dom";
import fadeRight from "../../animations/fadeRight";
import "./styles.css";

class DesktopContainer extends Component {
  state = {
    search: "",
  };

  searchTimeout = null;

  hideFixedMenu = () => this.setState({ fixed: false });
  showFixedMenu = () => this.setState({ fixed: true });

  handleChangeSearch(value) {
    this.setState({ search: value }, () => {
      if (this.state.search.length >= 3) {
        clearTimeout(this.searchTimeout);
        this.searchTimeout = setTimeout(() => {
          let searchTerm = this.state.search.toLowerCase().split(" ").join("-");
          this.props.history.push(`/pesquisa/${searchTerm}`);
        }, 800);
      } else if (this.state.search.length === 0) {
        clearTimeout(this.searchTimeout);
        this.props.history.push(`/inicio`);
      }
    });
  }

  handleSearch() {
    const { search } = this.state;

    let searchTerm = search.toLowerCase().split(" ").join("-");
    this.props.history.push(`/pesquisa/${searchTerm}`);
  }

  

  render() {
    const {
      children,
      home,
      currentRoute,
      authenticated,
      sectors,
      store,
      cartItemsCount,
      getWidth,
    } = this.props;
    const { fixed, search } = this.state;

    return (
      <Responsive getWidth={getWidth} minWidth={Responsive.onlyTablet.minWidth}>
        <Visibility
          once={false}
          onBottomPassed={this.showFixedMenu}
          onBottomPassedReverse={this.hideFixedMenu}
          style={{ minHeight: home ? 600 : "none" }}
        >
          <Segment
            textAlign="center"
            style={{
              minHeight: home ? 600 : "none",
              maxHeight: home ? 600 : "none",
              padding: "1em 0em 0em 0em",
              backgroundColor: "var(--header-color, #ffffff)",
            }}
            vertical
          >
            <Menu
              className="header-container"
              fixed={fixed ? "top" : null}
              secondary={!fixed}
              size="large"
            >
              <Container className="header-top-part-container">
                <Menu.Item position="left" as={Link} to="/inicio">
                  <img
                    src={store.logo}
                    alt={store.name}
                    className="header-logo"
                  />
                </Menu.Item>
                <Menu.Item style={{ width: "50%" }}>
                  <Input
                    className="header-search-input"
                    action={{
                      icon: "search",
                      onClick: () => this.handleSearch(),
                    }}
                    placeholder="O que você está procurando ?"
                    value={search}
                    onChange={(e, { value }) => this.handleChangeSearch(value)}
                  />
                </Menu.Item>
                {!authenticated && (
                  <Menu.Item position="right">
                    <Button
                      className="login-button"
                      basic
                      size="small"
                      as={Link}
                      to="/login"
                    >
                      Login
                    </Button>
                    <div className="cart-toggle-container">
                      <Button
                        className="cart-toggle-icon"
                        as={Link}
                        to="/carrinho"
                        icon="shopping cart icon"
                      />
                      {cartItemsCount > 0 ? <div>{cartItemsCount}</div> : <></>}
                    </div>
                  </Menu.Item>
                )}
                {authenticated && (
                  <>
                    <Menu.Item position="right">
                      <Button
                        className="cart-toggle-icon"
                        icon="user"
                        as={Link}
                        to="/perfil/meus-dados"
                      />
                      <div className="cart-toggle-container">
                        <Button
                          className="cart-toggle-icon"
                          as={Link}
                          to="/carrinho"
                          icon="shopping cart icon"
                        />
                        {cartItemsCount > 0 ? <div>{cartItemsCount}</div> : <></>}
                      </div>
                    </Menu.Item>
                  </>
                )}
              </Container>
            </Menu>
            <Menu className="secondary-menu" secondary>
              <Menu.Item
                as={Link}
                to="/inicio"
                active={currentRoute === "inicio"}
              >
                Inicio
              </Menu.Item>
              {sectors.map((sector) =>
                sector.categories.length > 0 ? (
                  <Dropdown
                    as={Link}
                    to={`/setor/${sector.id}`}
                    item
                    simple
                    text={sector.name}
                    key={sector.id}
                  >
                    <Dropdown.Menu>
                      {sector.categories.map((category) =>
                        category.subcategories.length > 0 ? (
                          <Dropdown.Item key={sector.id} as="div">
                            <Dropdown
                              simple
                              as={Link}
                              to={`/setor/${sector.id}/categoria/${category.id}`}
                              text={category.name}
                              className="header-dropdown-item"
                            >
                              <Dropdown.Menu>
                                {category.subcategories.map((subcategory) => (
                                  <Dropdown.Item
                                    key={subcategory.id}
                                    as={Link}
                                    to={`/setor/${sector.id}/categoria/${category.id}/subcategoria/${subcategory.id}`}
                                  >
                                    {subcategory.name}
                                  </Dropdown.Item>
                                ))}
                              </Dropdown.Menu>
                            </Dropdown>
                          </Dropdown.Item>
                        ) : (
                          <Dropdown.Item
                            key={category.id}
                            as={Link}
                            to={`/setor/${sector.id}/categoria/${category.id}`}
                          >
                            {category.name}
                          </Dropdown.Item>
                        )
                      )}
                    </Dropdown.Menu>
                  </Dropdown>
                ) : (
                  <Menu.Item
                    key={sector.id}
                    as={Link}
                    active={currentRoute === `setor/${sector.id}`}
                    to={`/setor/${sector.id}`}
                  >
                    {sector.name}
                  </Menu.Item>
                )
              )}
              <Menu.Item
                as={Link}
                to="/sobre"
                active={currentRoute === "sobre"}
              >
                Quem somos
              </Menu.Item>
              <Menu.Item
                as={Link}
                to="/contato"
                active={currentRoute === "contato"}
              >
                Contato
              </Menu.Item>
            </Menu>
          </Segment>
        </Visibility>
        {children}
      </Responsive>
    );
  }
}

export default withRouter(DesktopContainer);
