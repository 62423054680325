import React, { createContext, useState, useEffect, useContext } from "react";
import ReactPixel from 'react-facebook-pixel';

import createNotification from "../utils/notification";
import { getStore } from "../providers/storeProvider";

const StoreContext = createContext({
  store: null,
});

export const StoreProvider = ({ children }) => {
  const [loading, setLoading] = useState(true);
  const [store, setStore] = useState({});
  const [pixelReady, setPixelReady] = useState(false);

  useEffect(() => {
    async function getStoreData() {
      const result = await getStore();

      if (result.error) {
        console.log("Sem informações da loja");
        return createNotification(
          "error",
          "Erro",
          "Ocorreu um erro ao tentar carregar as informações da loja"
        );
      } else {
        initFacebookPixel(result);
      }
      //adding favicon and title
      await new Promise((resolve, reject) => {
        let link =
          document.querySelector('link[rel="shortcut icon"]') ||
          document.querySelector('link[rel="icon"]');

        if (!link) {
          link = document.createElement("link");
          link.id = "favicon";
          link.rel = "shortcut icon";
          document.head.appendChild(link);
        }

        link.href = result.favicon;

        let htmlTitle = document.querySelector("title");

        if (!htmlTitle) {
          htmlTitle = document.createElement("title");
          htmlTitle.innerHTML = result.name;
          document.head.appendChild(htmlTitle);
        }

        resolve();
      });

      setLoading(false);
      setStore(result);
    }

    getStoreData();
  }, []);

  function initFacebookPixel (storeInfor) {
    const { facebook_pixel } = storeInfor;

    if(facebook_pixel) {
      const options = {
        autoConfig: true, 
        debug: false,
      };
      ReactPixel.init(facebook_pixel, {}, options);
      ReactPixel.pageView();
      setPixelReady(true);
    }
  }

  return (
    <StoreContext.Provider value={{ store, pixelReady }}>
      {!loading && children}
    </StoreContext.Provider>
  );
};

export function useStore() {
  const context = useContext(StoreContext);

  return context;
}
