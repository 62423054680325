import React, { Component, useEffect, useState } from "react";
import {
  Container,
  Button,
  Icon,
  Responsive,
  Visibility,
  Segment,
  Menu,
  Sidebar,
  Dropdown,
} from "semantic-ui-react";
import DesktopHeader from "./DesktopHeader";
import MobileHeader from "./MobileHeader";
import { useAuth } from "../../contexts/auth";
import { useStore } from "../../contexts/store";
import { useCart } from "../../contexts/cart";
import { Link, useLocation } from "react-router-dom";
import { getSectors } from "../../providers/productProvider";
import createNotification from "../../utils/notification";
import "./styles.css";

const getWidth = () => {
  const isSSR = typeof window === "undefined";

  return isSSR ? Responsive.onlyTablet.minWidth : window.innerWidth;
};

const ResponsiveContainer = ({ children, home }) => {
  const location = useLocation();
  const { signed, signOut } = useAuth();
  const { store } = useStore();
  const { items: cartItems } = useCart();

  const [currentRoute, setCurrentRoute] = useState("inicio");
  const [sectors, setSectors] = useState([]);

  useEffect(() => {
    setCurrentRoute(location.pathname.replace("/", ""));
  }, [location]);

  useEffect(() => {
    async function getSectorsData() {
      const response = await getSectors();

      if (response.error)
        return createNotification(
          "error",
          "Erro",
          "Ocorreu um erro ao carregar a lista de setores"
        );

      return setSectors(response);
    }

    getSectorsData();
  }, []);

  return (
    <div>
      <DesktopHeader
        currentRoute={currentRoute}
        authenticated={signed}
        signOut={signOut}
        sectors={sectors}
        store={store}
        cartItemsCount={cartItems.length}
        getWidth={getWidth}
      >
        {children}
      </DesktopHeader>
      <MobileHeader
        currentRoute={currentRoute}
        authenticated={signed}
        signOut={signOut}
        store={store}
        cartItemsCount={cartItems.length}
        getWidth={getWidth}
      >
        {children}
      </MobileHeader>
    </div>
  );
};

export default ResponsiveContainer;
