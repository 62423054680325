import provider from "./config";

export async function checkout(payload, token) {
  try {
    const response = await provider.post("/checkout/", payload, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (err) {
    switch (err.response.status) {
      default:
        return {
          error: true,
          message: "Ocorreu um erro ao tentar realizar o pagamento",
        };
      case 400:
        return {
          error: true,
          message: "Ocorreu um erro, tente novamente mais tarde",
        };
      case 401:
        return { error: true, message: "Dados inválidos" };
      case 402:
        return {
          error: true,
          message: "Pagamento recusado",
        };
      case 403:
        return { error: true, message: "Pagamento recusado" };
      case 404:
        return { error: true, message: "Dados inválidos" };
      case 500:
        return { error: true, message: "Erro no servidor" };
      case 502:
        return {
          error: true,
          message: "O servidor está atualizando, tente novamente mais tarde",
        };
    }
  }
}

export async function boletoCheckout(payload, token) {
  try {
    const response = await provider.post("/boleto/", payload, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (err) {
    switch (err.response.status) {
      default:
        return {
          error: true,
          message: "Ocorreu um erro ao tentar realizar o pagamento",
        };
      case 400:
        return {
          error: true,
          message: "Ocorreu um erro, tente novamente mais tarde",
        };
      case 401:
        return { error: true, message: "Dados inválidos" };
      case 402:
        return {
          error: true,
          message: "Pagamento recusado",
        };
      case 403:
        return { error: true, message: "Pagamento recusado" };
      case 404:
        return { error: true, message: "Dados inválidos" };
      case 500:
        return { error: true, message: "Erro no servidor" };
      case 502:
        return {
          error: true,
          message: "O servidor está atualizando, tente novamente mais tarde",
        };
    }
  }
}
