import React from "react";
import { Divider, Icon, Button } from "semantic-ui-react";
import { Link } from "react-router-dom";
import QuantityPicker from "../QuantityPicker";

const CartList = ({
  data,
  removeItem,
  cleanCart,
  changeQuantityItem,
  itemsStock,
  payment,
}) => {

  function handleStockCount(id){
    const itemStock = itemsStock.filter(stock => stock.id === id)[0];

    if(itemStock){
      return itemStock.stock;
    }

    return 0;
  }

  return (
    <section>
      {data.map((cartItem) => (
        <div key={cartItem.id}>
          <div className="cart-item row align-items-center justify-content-between">
            <Link
              to={`/produto/${cartItem.productBaseId}`}
              className="cart-link row align-items-center"
            >
              <img className="cart-image" alt="card-item" src={cartItem.image} />
              <div className="cart-item-content column">
                <strong>
                  {cartItem.count}x {cartItem.name}
                </strong>
                <p>Preço: R${(cartItem.price * cartItem.count).toFixed(2)}</p>
              </div>
            </Link>
            <div className="cart-actions row align-items-center">
              <QuantityPicker
                value={cartItem.count}
                onChange={(value) => changeQuantityItem(cartItem.id, value)}
                maxValue={handleStockCount(cartItem.id)}
              />
              <Icon
                className="cart-remove-icon"
                name="trash"
                color="red"
                onClick={() => removeItem(cartItem.id)}
                size="large"
              />
            </div>
          </div>
          <Divider />
        </div>
      ))}

      {data.length > 0 && !payment && (
        <>
          <p className="cart-clean-cart-label" onClick={cleanCart}>
            Limpar carrinho
          </p>

          <Button fluid primary as={Link} to="/pagamento">
            Finalizar compra
          </Button>
        </>
      )}
    </section>
  )
};

export default CartList;
