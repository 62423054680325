import React from "react";
import { Step } from "semantic-ui-react";

export default function CheckoutStep({ status }) {
  return (
    <Step.Group ordered widths="4" size="small" className="checkout-step">
      <Step active={status === 0} completed={status > 0}>
        <Step.Content>
          <Step.Title>Endereço de entrega</Step.Title>
        </Step.Content>
      </Step>

      <Step active={status === 1} disabled={status < 1} completed={status > 1}>
        <Step.Content>
          <Step.Title>Tipo de Pagamento</Step.Title>
        </Step.Content>
      </Step>

      <Step active={status === 2} disabled={status < 2} completed={status > 2}>
        <Step.Content>
          <Step.Title>Pagamento</Step.Title>
        </Step.Content>
      </Step>

      <Step disabled={status < 3} completed={status === 3}>
        <Step.Content>
          <Step.Title>Pedido Realizado</Step.Title>
        </Step.Content>
      </Step>
    </Step.Group>
  );
}
