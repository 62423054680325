import React, { useEffect } from "react";
import "react-notifications/lib/notifications.css";
import { NotificationContainer } from "react-notifications";
import { Helmet } from "react-helmet";

import Routes from "./routes";
import ContextProvider from "./contexts";
import WhatsappButton from "./components/WhatsappButton";

function handleEnvStyle(style) {
  return Object.keys(style).map((key) => {
    return document.documentElement.style.setProperty(`--${key}`, style[key]);
  });
}

export default function App() {
  useEffect(() => {
    handleEnvStyle({
      "primary-color": process.env.REACT_APP_PRIMARY_COLOR,
      "secondary-background": process.env.REACT_APP_SECONDARY_BACKGROUND,
      "primary-text-color": process.env.REACT_APP_PRIMARY_TEXT_COLOR,
      "secondary-text-color": process.env.REACT_APP_SECONDARY_TEXT_COLOR,
      "icon-color": process.env.REACT_APP_ICON_COLOR,
      "header-color": process.env.REACT_APP_HEADER_COLOR,
      "header-logo-size": process.env.REACT_APP_HEADER_LOGO_SIZE,
    });
  }, []);

  // const pixel = "<script> !function(f,b,e,v,n,t,s) {if(f.fbq)return;n=f.fbq=function(){n.callMethod? n.callMethod.apply(n,arguments):n.queue.push(arguments)};if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';n.queue=[];t=b.createElement(e);t.async=!0;t.src=v;s=b.getElementsByTagName(e)[0];s.parentNode.insertBefore(t,s)}(window,document,'script','https://connect.facebook.net/en_US/fbevents.js'); fbq('init', '321853029550679'); fbq('track', 'PageView');</script><noscript><img height='1' width='1' src='https://www.facebook.com/tr?id=321853029550679&ev=PageView&noscript=1'/></noscript>";

  return (
    <ContextProvider>
      <Routes />
      <NotificationContainer />
      <WhatsappButton />
    </ContextProvider>
  );
}
