import { NotificationManager } from "react-notifications";

const Notification = (type, title, message) => {
  switch (type) {
    default:
      break;
    case "info":
      NotificationManager.info(message, title);
      break;
    case "success":
      NotificationManager.success(message, title);
      break;
    case "warning":
      NotificationManager.warning(message, title, 3000);
      break;
    case "error":
      NotificationManager.error(message, title, 3000, () => {});
      break;
  }
};

export default Notification;
