import React from "react";
import { Checkbox } from "semantic-ui-react";
import "./styles.css";

function CheckContainer({ checked, onClick, children, className, disabled=false }) {
  return (
    <div
      className={"row align-items-center check-container " + className}
      onClick={() => onClick()}
    >
      <Checkbox disabled={disabled} checked={checked} onChange={() => onClick()} />
      <div className="content-container">{children}</div>
    </div>
  );
}

export default CheckContainer;
