import React from "react";
import { AuthProvider } from "./auth";
import { CartProvider } from "./cart";
import { StoreProvider } from "./store";

const ContextProvider = ({ children }) => (
  <StoreProvider>
    <AuthProvider>
      <CartProvider>{children}</CartProvider>
    </AuthProvider>
  </StoreProvider>
);

export default ContextProvider;
