export const stateOptions = [
  { value: "Acre", text: "Acre" },
  { value: "Alagoas", text: "Alagoas" },
  { value: "Amapá", text: "Amapá" },
  { value: "Amazonas", text: "Amazonas" },
  { value: "Bahia", text: "Bahia" },
  { value: "Ceará", text: "Ceará" },
  { value: "Distrito Federal", text: "Distrito Federal" },
  { value: "Espírito Santo", text: "Espírito Santo" },
  { value: "Goiás", text: "Goiás" },
  { value: "Maranhão", text: "Maranhão" },
  { value: "Mato Grosso", text: "Mato Grosso" },
  { value: "Mato Grosso do Sul", text: "Mato Grosso do Sul" },
  { value: "Minas Gerais", text: "Minas Gerais" },
  { value: "Pará", text: "Pará" },
  { value: "Paraíba", text: "Paraíba" },
  { value: "Paraná", text: "Paraná" },
  { value: "Pernambuco", text: "Pernambuco" },
  { value: "Piauí", text: "Piauí" },
  { value: "Rio de Janeiro", text: "Rio de Janeiro" },
  { value: "Rio Grande do Norte", text: "Rio Grande do Norte" },
  { value: "Rio Grande do Sul", text: "Rio Grande do Sul" },
  { value: "Rondônia", text: "Rondônia" },
  { value: "Roraima", text: "Roraima" },
  { value: "Santa Catarina", text: "Santa Catarina" },
  { value: "São Paulo", text: "São Paulo" },
  { value: "Sergipe", text: "Sergipe" },
  { value: "Tocantins", text: "Tocantins" },
];

export const highlightData = [
  {
    id: 1,
    image:
      "https://images.unsplash.com/photo-1496217590455-aa63a8350eea?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1400&q=80",
    title: "Summer Breeze",
  },
  {
    id: 2,
    image:
      "https://images.unsplash.com/photo-1550944438-0b16a7a3efba?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1400&q=80",
    title: "Urban Street",
  },
  {
    id: 3,
    image:
      "https://images.unsplash.com/photo-1456444029056-7dfaeeb83a19?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1400&q=80",
    title: "Dia dos Pais",
  },
];
