import React, { useState, useEffect } from "react";
import InputMask from "react-input-mask";
import { Segment, Form, Input, Select } from "semantic-ui-react";
import { useStore } from "../../contexts/store";
import { PIXEL_TYPE_CHOICES, TrackPixelEvent } from "../../utils/facebookPixel";

function checkIfFilled(obj) {
  let filled = true;

  for (let key in obj) {
    if (obj[key].replace(/_/g, "") === "") {
      filled = false;
      break;
    }
  }

  return filled;
}

export default function CheckoutPayment({ total, installments, setCardInfo, isFreeShipping }) {
  const { store, pixelReady } = useStore();

  const [card, setCard] = useState({
    number: "",
    holderName: "",
    expMonth: "",
    expYear: "",
    ccv: "",
    installments: "1",
  });

  const [installmentOptions, setInstallmentOptions] = useState([]);

  function handleChangeValue(key, value) {
    setCard({ ...card, [key]: value });
  }

  useEffect(() => {
    if(pixelReady) {
      TrackPixelEvent(store.events, PIXEL_TYPE_CHOICES.AddPaymentInfo);
    }
  }, [])

  useEffect(() => {
    if (checkIfFilled(card)) {
      setCardInfo(card);
    } else {
      setCardInfo(null);
    }
  }, [card]);

  useEffect(() => {
    const tempList = [];
    for (let i = 0; i < installments; i++) {
      const option = {
        value: i + 1,
        text: `${i + 1}x R$${(total / (i + 1)).toFixed(2)}`,
      };
      tempList.push(option);
    }

    setInstallmentOptions(tempList);
  }, [total, installments]);

  return (
    <Segment className="checkout-payment-container">
      <Form>
        <Form.Group widths="equal">
          <Form.Field required>
            <label style={{ fontWeight: "500" }}>
              Nome impresso no cartão:
            </label>
            <Input
              value={card.holderName}
              onChange={(e, { value }) =>
                handleChangeValue("holderName", value)
              }
              placeholder="Nome impresso no cartão"
              required
            />
          </Form.Field>
        </Form.Group>
        <Form.Group widths={16}>
          <Form.Field width={8} required>
            <label style={{ fontWeight: "500" }}>Número do cartão:</label>
            <Input
              value={card.number}
              onChange={(e, { value }) => handleChangeValue("number", value)}
              placeholder="Número do cartão"
              icon="credit card"
              required
            />
          </Form.Field>
          <Form.Field width={3} required>
            <label style={{ fontWeight: "500" }}>Mês:</label>
            <InputMask
              value={card.expMonth}
              onChange={(e) => handleChangeValue("expMonth", e.target.value)}
              mask="99"
              placeholder="00"
              required
            />
          </Form.Field>
          <Form.Field width={3} required>
            <label style={{ fontWeight: "500" }}>Ano:</label>
            <InputMask
              value={card.expYear}
              onChange={(e) => handleChangeValue("expYear", e.target.value)}
              mask="9999"
              placeholder="0000"
              required
            />
          </Form.Field>
          <Form.Field width={2} required>
            <label style={{ fontWeight: "500" }}>CVV:</label>
            <InputMask
              value={card.ccv}
              onChange={(e) => handleChangeValue("ccv", e.target.value)}
              mask="999"
              placeholder="000"
              required
            />
          </Form.Field>
        </Form.Group>
        <label style={{ fontWeight: "500" }}>Parcelamento:</label>
        <Form.Field
          required
          onChange={(e, { value }) =>
            handleChangeValue("installments", String(value))
          }
          control={Select}
          options={installmentOptions}
          placeholder={`1x R$${total}`}
          disabled={isFreeShipping}
        />
      </Form>
    </Segment>
  );
}
