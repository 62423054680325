import React, { createContext, useState, useEffect, useContext } from "react";
import * as auth from "../providers/authProvider";
import { TrackPixelEvent, PIXEL_TYPE_CHOICES } from "../utils/facebookPixel";
import createNotification from "../utils/notification";
import { useStore } from "./store";

const AuthContext = createContext({
  signed: null,
  user: null,
  signIn: null,
  signUp: null,
  signOut: null,
  updateUser: null,
  fromPayment: null,
  setFromPayment: null
});

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [from, setFrom] = useState(null);
  const { store, pixelReady } = useStore();

  useEffect(() => {
    if (user !== null && Object.keys(store).length > 0) {
      const storeTag = store.name.replace(" ", "").toLowerCase();
      localStorage.setItem(`@${storeTag}:user`, JSON.stringify(user));
    }
  }, [user, store]);

  useEffect(() => {
    async function loadStoragedData() {
      const storeTag = store.name.replace(" ", "").toLowerCase();
      const storagedUser = localStorage.getItem(`@${storeTag}:user`);

      if (storagedUser) {
        setUser(JSON.parse(storagedUser));
      }
      setLoading(false);
    }

    if (Object.keys(store).length > 0)
      loadStoragedData();
  }, [store]);

  async function signIn(payload, from = null) {
    const response = await auth.signIn(payload);

    if (response.error) {
      return createNotification("error", "Erro", "Email ou Senha inválido(s)");
    }

    setFrom(from);
    return setUser(response);
  }

  async function signUp(payload, from = null) {
    const response = await auth.signUp(payload);

    if (response.error) {
      return createNotification(
        "error",
        "Erro",
        response.message
      );
    }

    setFrom(from);
    if(pixelReady) {
      TrackPixelEvent(store.events, PIXEL_TYPE_CHOICES.CompleteRegistration);
    }
    return setUser(response);
  }

  function signOut() {
    localStorage.clear();
    setUser(null);
  }

  async function updateUser(id, payload) {
    const response = await auth.updateProfile(id, payload, user.token);

    if (response.error) {
      return false;
    }

    setUser(response);
    return true;
  }

  return (
    <AuthContext.Provider
      value={{
        signed: !!user,
        user,
        signIn,
        signUp,
        signOut,
        updateUser,
        from,
        setFrom
      }}
    >
      {!loading && children}
    </AuthContext.Provider>
  );
};

export function useAuth() {
  const context = useContext(AuthContext);

  return context;
}
