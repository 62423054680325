import React, { useState, useEffect } from "react";


import { Form, Button, Grid, Card, Feed } from "semantic-ui-react";
import { useStore } from "../../contexts/store";
import { useAuth } from "../../contexts/auth";
import { sendContactMessage } from "../../providers/storeProvider";
import { validateEmail } from "../../utils/validateForm";
import createNotification from "../../utils/notification";
import "./styles.css";
import { TrackPixelEvent, PIXEL_TYPE_CHOICES } from "../../utils/facebookPixel";

export default function Contact() {
  const { store, pixelReady } = useStore();
  const { user } = useAuth();
  const [name, setName] = useState(
    user ? `${user.first_name} ${user.last_name}` : ""
  );
  const [email, setEmail] = useState(user ? user.email : "");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if(pixelReady) {
      TrackPixelEvent(store.events, PIXEL_TYPE_CHOICES.Contact);
    }
  }, [])

  async function handleSendContactMessage(e) {
    e.preventDefault();
    setLoading(true);

    if (!validateEmail(email)) {
      setLoading(false);
      return createNotification("error", "Erro", "Email inválido");
    }

    let payload = {
      nome: name,
      codigo: email,
      descricao: subject,
      observacao: message,
      idClasse: '637e6137438da52c609b7dd5',
    };

    const result = await sendContactMessage(payload);

    if (result.error) {
      setLoading(false);
      return createNotification(
        "error",
        "Erro",
        "Ocorreu um erro ao tentar enviar a mensagem de contato"
      );
    }

    setName(user ? `${user.first_name} ${user.last_name}` : "");
    setEmail(user ? user.email : "");
    setSubject("");
    setMessage("");

    setLoading(false);
    return createNotification(
      "success",
      "Sucesso",
      "Mensagem enviada com sucesso"
    );
  }

  return (
    <Grid stackable id="contact-container" className="main-container">
      <Grid.Row centered columns={3}>
        <Grid.Column width={6}>
          {store.address_postcode !== '00000-000' && (
            <Card>
            <Card.Content className="contact-card-header">
              <Card.Header>Nosso endereço</Card.Header>
            </Card.Content>
            <Card.Content>
              <Feed>
                <Feed.Event>
                  <Feed.Label icon="pin" />
                  <Feed.Content>
                    <Feed.Summary className="contact-card-content">
                      <p>
                        {store.address_street}, {store.address_number} -{" "}
                        {store.address_district}
                      </p>
                      <p>
                        {store.address_city} - {store.address_state}
                      </p>
                      <p>{store.address_complement}</p>
                      <p>{store.address_postcode}</p>
                    </Feed.Summary>
                  </Feed.Content>
                </Feed.Event>
              </Feed>
            </Card.Content>
          </Card>
          )}
          <Card>
            <Card.Content className="contact-card-header">
              <Card.Header>Telefones</Card.Header>
            </Card.Content>
            <Card.Content>
              <Feed>
                {store.phone && (
                  <Feed.Event>
                    <Feed.Label icon="phone" />
                    <Feed.Content>
                      <Feed.Summary className="contact-card-content">
                        <p>{store.phone}</p>
                      </Feed.Summary>
                    </Feed.Content>
                  </Feed.Event>
                )}
              </Feed>
            </Card.Content>
          </Card>
          <Card>
            <Card.Content className="contact-card-header">
              <Card.Header>Redes sociais</Card.Header>
            </Card.Content>
            <Card.Content>
              <Feed>
                {store.instagram && (
                  <Feed.Event>
                    <Feed.Label icon="instagram" />
                    <Feed.Content>
                      <Feed.Summary className="contact-card-content">
                        <p>@{store.instagram}</p>
                      </Feed.Summary>
                    </Feed.Content>
                  </Feed.Event>
                )}
                {store.facebook && (
                  <Feed.Event>
                    <Feed.Label icon="facebook" />
                    <Feed.Content>
                      <Feed.Summary className="contact-card-content">
                        <p>/{store.facebook}</p>
                      </Feed.Summary>
                    </Feed.Content>
                  </Feed.Event>
                )}
                {store.twitter && (
                  <Feed.Event>
                    <Feed.Label icon="twitter" />
                    <Feed.Content>
                      <Feed.Summary className="contact-card-content">
                        <p>@{store.twitter}</p>
                      </Feed.Summary>
                    </Feed.Content>
                  </Feed.Event>
                )}
              </Feed>
            </Card.Content>
          </Card>
        </Grid.Column>
        <Grid.Column width={8}>
          <Card style={{ width: "100%" }}>
            <Card.Content className="contact-card-header">
              <Card.Header>Entre em contato</Card.Header>
            </Card.Content>
            <Card.Content>
              <Feed>
                <Feed.Event>
                  <Feed.Content>
                    <Feed.Summary className="contact-card-content">
                      <Form
                        onSubmit={handleSendContactMessage}
                        style={{
                          padding: "1em 2em",
                        }}
                      >
                        <Form.Field required>
                          <label>Nome:</label>
                          <input
                            placeholder="Digite seu nome"
                            value={name}
                            required
                            onChange={(e) => setName(e.target.value)}
                          />
                        </Form.Field>
                        <Form.Field required>
                          <label>Email:</label>
                          <input
                            placeholder="Digite seu email"
                            value={email}
                            required
                            onChange={(e) => setEmail(e.target.value)}
                          />
                        </Form.Field>
                        <Form.Field required>
                          <label>Assunto:</label>
                          <input
                            placeholder="Digite o assunto do seu contato"
                            required
                            value={subject}
                            onChange={(e) => setSubject(e.target.value)}
                          />
                        </Form.Field>
                        <Form.TextArea
                          required
                          label="Mensagem:"
                          placeholder="Digite o motivo do seu contato"
                          value={message}
                          onChange={(e, { value }) => setMessage(value)}
                        />
                        <Button type="submit" primary>
                          {loading ? (
                            <i aria-hidden="true" class="spinner icon"></i>
                          ) : (
                            "Enviar"
                          )}
                        </Button>
                      </Form>
                    </Feed.Summary>
                  </Feed.Content>
                </Feed.Event>
              </Feed>
            </Card.Content>
          </Card>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
}
