import React, {useEffect, useState} from 'react';

import {useStore} from "../../contexts/store";

import WhatsappLogo from "../../assets/images/whatsapp.png";
import "./styles.css";

function WhatsappButton() {

    const {store} = useStore();
    const [whatsapp, setWhatsapp] = useState("");

    useEffect(() => {
        const {whatsapp_number} = store;
        if(whatsapp_number)
            setWhatsapp(whatsapp_number.replace(/\D/g, ""));
    }, [store]);

    return (
        whatsapp.length > 0 ? 
        <a  
            target="_blank"
            rel="noopener noreferrer"
            href={`https://wa.me/55${whatsapp}?text=Olá!`}
            className="floatingButton"
        >
            <img src={WhatsappLogo} alt="Whatsapp Logo" />
        </a>
        : (
            <>
            </>
        )
    );
}

export default WhatsappButton;