import React, { Component } from "react";
import MultilevelSidebar from "react-multilevel-sidebar";
import "react-multilevel-sidebar/src/Sidebar.css";
import { Link, Redirect, withRouter } from "react-router-dom";
import {
  Responsive,
  Sidebar,
  Segment,
  Container,
  Button,
  Menu,
  Icon,
  Input,
} from "semantic-ui-react";
import { getSectors } from "../../providers/productProvider";
import createNotification from "../../utils/notification";

class MobileHeader extends Component {
  constructor() {
    super();
    this.state = {
      isOpen: false,
      options: [],
      sectors: [],
      redirect: null,
      currentIndex: 0,
      search: "",
    };

    this.searchTimeout = null;
  }

  handleChangeSearch(value) {
    this.setState({ search: value }, () => {
      if (this.state.search.length >= 3) {
        clearTimeout(this.searchTimeout);
        this.searchTimeout = setTimeout(() => {
          let searchTerm = this.state.search.toLowerCase().split(" ").join("-");
          this.props.history.push(`/pesquisa/${searchTerm}`);
        }, 800);
      } else if (this.state.search.length === 0) {
        clearTimeout(this.searchTimeout);
        this.props.history.push(`/inicio`);
      }
    });
  }

  handleSearch() {
    const { search } = this.state;

    let searchTerm = search.toLowerCase().split(" ").join("-");
    this.props.history.push(`/pesquisa/${searchTerm}`);
  }

  async getSectorsData() {
    const response = await getSectors();

    if (response.error)
      return createNotification(
        "error",
        "Erro",
        "Ocorreu um erro ao carregar a lista de setores"
      );

    return this.setState({ sectors: response }, () => this.handleMountMenu());
  }

  handleMountMenu() {
    const { sectors } = this.state;
    const { authenticated } = this.props;

    const tempOptions = [];
    let currentIndex = 2;

    tempOptions.push({
      hideBorder: true,
      content: [
        {
          id: 1,
          name: "Inicio",
          route: "/inicio",
        },
      ],
    });

    sectors.map((sector) => {
      let option = {
        hideBorder: true,
      };

      if (sector.categories.length > 0) {
        option = {
          ...option,
          content: [
            {
              id: currentIndex,
              name: sector.name,
              children: [
                {
                  hideBorder: true,
                  content: [{
                    id: currentIndex,
                    name: `Ir para ${sector.name}`,
                    route: `/setor/${sector.id}/`,
                  }],
                },
                ...sector.categories.map((category) => {
                currentIndex += 1;
                let categoryItem = {
                  id: currentIndex,
                  name: category.name,
                };

                let categoryOption;
                if (category.subcategories.length > 0) {
                  currentIndex+=1;
                  categoryOption = {
                    hideBorder: true,
                    content: [
                      {
                        ...categoryItem,
                        children: [
                          {
                            hideBorder: true,
                              content: [
                              {
                                id: currentIndex,
                                name: `Ir para ${category.name}`,
                                route: `/setor/${sector.id}/categoria/${category.id}/`,
                              },
                            ],
                          },
                          ...category.subcategories.map((subcategory) => {
                            currentIndex += 1;
                            const subcategoryOption = {
                              hideBorder: true,
                              content: [
                                {
                                  id: currentIndex,
                                  name: subcategory.name,
                                  route: `/setor/${sector.id}/categoria/${category.id}/subcategoria/${subcategory.id}`,
                                },
                              ],
                            };

                            return subcategoryOption;
                          }),
                        ]
                      },
                    ],
                  };
                } else {
                  categoryItem = {...categoryItem, route: `/setor/${sector.id}/categoria/${category.id}`,};

                  categoryOption = {
                    hideBorder: true,
                    content: [categoryItem],
                  };
                }

                return categoryOption;
              }),
            ]
            },
          ],
        };
      } else {
        option = {
          ...option,
          content: [
            {
              id: currentIndex,
              name: sector.name,
              route: `/setor/${sector.id}`,
            },
          ],
        };
      }

      tempOptions.push(option);
      currentIndex += 1;
    });

    tempOptions.push({
      hideBorder: true,
      content: [
        {
          id: currentIndex + 1,
          name: "Quem somos",
          route: "/sobre",
        },
      ],
    });

    tempOptions.push({
      hideBorder: true,
      content: [
        {
          id: currentIndex + 2,
          name: "Contato",
          route: "/contato",
        },
      ],
    });

    this.setState({ options: tempOptions, currentIndex });
  }

  componentDidMount() {
    this.getSectorsData();
  }

  handleSidebarToggle = (isOpen) => {
    if (isOpen) document.body.style["overflow-y"] = "hidden";
    else document.body.style["overflow-y"] = "scroll";
    this.setState({ isOpen });
  };

  handleClickLink = async (route, event = null) => {
    if (event === "signOut") {
      this.handleSidebarToggle(false);
      await this.props.signOut();
      return this.handleMountMenu();
    }

    if (route !== undefined) {
      this.handleSidebarToggle(false);
      setTimeout(() => {
        return this.setState({ redirect: route }, () => {
          this.setState({ redirect: null });
        });
      }, 400);
    }
  };

  handleMenuOptions = (authenticated, options, currentIndex) => {
    let menuOptions = [...options];

    if (authenticated) {
      menuOptions = [
        ...menuOptions,
        {
          hideBorder: true,
          content: [
            {
              id: currentIndex + 3,
              name: "Perfil",
              route: "/perfil/meus-dados",
            },
          ],
        },
        {
          hideBorder: true,
          content: [
            {
              id: currentIndex + 4,
              name: "Carrinho",
              route: "/carrinho",
            },
          ],
        },
        {
          hideBorder: true,
          content: [
            {
              id: currentIndex + 5,
              name: "Sair",
              event: "signOut",
            },
          ],
        },
      ];
    } else {
      menuOptions = [
        ...menuOptions,
        {
          hideBorder: true,
          content: [
            {
              id: currentIndex + 3,
              name: "Login",
              route: "/login",
            },
          ],
        },
        {
          hideBorder: true,
          content: [
            {
              id: currentIndex + 4,
              name: "Carrinho",
              route: "/carrinho",
            },
          ],
        },
      ];
    }

    return menuOptions;
  };

  render() {
    const { children, getWidth, authenticated, store, cartItemsCount } = this.props;
    const { isOpen, options, currentIndex, search } = this.state;

    if (this.state.redirect) {
      return <Redirect to={this.state.redirect} />;
    }

    return (
      <Responsive
        as={Sidebar.Pushable}
        getWidth={getWidth}
        maxWidth={Responsive.onlyMobile.maxWidth}
      >
        <MultilevelSidebar
          open={isOpen}
          onToggle={() => this.handleSidebarToggle(false)}
          options={this.handleMenuOptions(authenticated, options, currentIndex)}
          header={`Bem-vindo(a) a ${store.name}`}
          headerClassName="header-title"
          onItemClick={(option) =>
            this.handleClickLink(
              option.route,
              option.event ? option.event : null
            )
          }
        />
        <Sidebar.Pusher dimmed={isOpen}>
          <Segment
            textAlign="center"
            style={{
              // minHeight:  0,
              // maxHeight: 0,
              padding: "1em 0em 0em 0em",
            }}
            vertical
          >
            <Container>
              <Menu pointing secondary size="large">
                <Menu.Item onClick={() => this.handleSidebarToggle(true)}>
                  <Icon name="sidebar" />
                </Menu.Item>
                {!authenticated && (
                  <Menu.Item position="right">
                    <Button
                      style={{
                        backgroundColor: "transparent",
                      }}
                      size="small"
                      as={Link}
                      to="/login"
                    >
                      Login
                    </Button>
                    <div className="cart-toggle-container">
                      <Button
                        className="cart-toggle-icon"
                        as={Link}
                        to="/carrinho"
                        icon="shopping cart icon"
                      />
                      {cartItemsCount > 0 ? <div>{cartItemsCount}</div> : <></>}
                    </div>
                  </Menu.Item>
                )}
                {authenticated && (
                  <>
                    <Menu.Item position="right">
                      <Button
                        className="cart-toggle-icon"
                        icon="user"
                        as={Link}
                        to="/perfil/meus-dados"
                      />
                      <div className="cart-toggle-container">
                        <Button
                          className="cart-toggle-icon"
                          as={Link}
                          to="/carrinho"
                          icon="shopping cart icon"
                        />
                        {cartItemsCount > 0 ? <div>{cartItemsCount}</div> : <></>}
                      </div>
                    </Menu.Item>
                  </>
                )}
              </Menu>
            </Container>
            <div className="ui action input header-search-input">
              <input
                style={{
                  borderRadius: 0,
                  borderTop: "1px solid rgba(0,0,0,0.1)",
                  borderLeft: "none",
                  borderRight: "none",
                  borderBottom: "none",
                }}
                placeholder="O que você está procurando ?"
                value={search}
                onChange={(e) => this.handleChangeSearch(e.target.value)}
              />
              <button style={{ borderRadius: 0 }} className="ui icon button">
                <i aria-hidden="true" className="search icon"></i>
              </button>
            </div>
          </Segment>
          {children}
        </Sidebar.Pusher>
      </Responsive>
    );
  }
}

export default withRouter(MobileHeader);
