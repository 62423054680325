import { formatDateYearMonthDay } from "../utils/validateForm";
import provider from "./config";

export async function checkEmail(email) {
  try {
    const response = await provider.get("/entidades/validar-email/", {
      params: {
        email
      }
    });
    return response.data;
  } catch (err) {
    return { error: true, message: err.message };
  }
}

export async function signIn(payload) {
  try {
    const response = await provider.post("/authentication/", payload);

    const data = response.data;
    const last_name = data.entidade.nome.split(' ');
    const first_name = last_name.shift()

    const user = {
      "id": data.entidade.id,
      "user": data.entidade.DUserGroup.id,
      "is_active": !data.entidade.inativo,
      "token": data.token,
      "first_name": first_name,
      "last_name": last_name,
      "email": data.entidade.email,
      "gender": data.entidade.genero || "Prefiro não dizer",
      "cpf_cnpj": data.entidade.cpfCnpj,
      "birthdate": formatDateYearMonthDay(data.entidade.dataNascimento),
      "phone": data.entidade.telefone,
      "cell_phone": data.entidade.celular,
      "address_street": data.entidade.endereco,
      "address_state": data.entidade.UF ? data.entidade.UF.nome : null,
      "address_postcode": data.entidade.cep,
      "address_city": data.entidade.Cidade ? data.entidade.Cidade.nome : null,
      "address_district": data.entidade.bairro,
      "address_number": data.entidade.numeroEndereco,
      "address_complement": data.entidade.complemento,
      "image": data.entidade.foto
    };
    return user;
  } catch (err) {
    return { error: true, message: err.message };
  }
}

export async function signUp(payload) {
  try {
    await provider.post("/entidades/", payload);
    const result = await signIn({
      usuario: payload.email,
      senha: payload.senha,
    });

    return result;
  } catch (err) {

    if(err.response && err.response.data){
      if(err.response.data.cpf_cnpj){
        return { error: true, message: "Este CPF já está cadastrado no sistema" };
      }
      if(err.response.data.phone){
        return { error: true, message: "Este telefone já está cadastrado no sistema" };
      }
    }

    return { error: true, message: "Ocorreu um erro ao tentar fazer o seu cadastro" };
  }
}

export async function updateProfile(id, payload, token) {
  try {
    const response = await provider.put(`/entidades/${id}/`, payload, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (err) {
    return { error: true, message: err.message };
  }
}

export async function getUserOrders(id, token) {
  try {
    const response = await provider.get(`/order?user=${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (err) {
    return { error: true, message: err.message };
  }
}