import React, {useState, useEffect} from 'react';
import { Link } from 'react-router-dom';
import {Segment} from "semantic-ui-react";
import CheckContainer from "../CheckContainer";
import {useAuth} from "../../contexts/auth";
import {useStore} from "../../contexts/store";

function CheckoutChoosePayment({total, installments, onChange, isFreeShipping}) {
    const {store} = useStore();
    const [paymentType, setPaymentType] = useState(null);
    const [canChooseBoleto, setCanChooseBoleto] = useState(false);
    const {user} = useAuth();

    useEffect(() => {
        if(checkIfAddressOk()){
            setCanChooseBoleto(true);
        }else {
            setCanChooseBoleto(false);
        }
    }, [user]);

    useEffect(() => {
        onChange(paymentType);
    }, [paymentType]);

    const checkIfAddressOk = () => {
        if(user.address_street
        && user.address_state
        && user.address_district
        && user.address_postcode
        && user.address_city) return true;
        else return false
    }

    return (
        <Segment className={"checkout-address"}>
            {store.credit_pay && (
                <CheckContainer
                    checked={paymentType === "card"}
                    onClick={() => setPaymentType("card")}
                >
                    <div className="column">
                    <p>
                        Cartão de crédito
                    </p>
                    <p>
                        R$ {total} { isFreeShipping ? "" : `ou em até ${installments}x de R$ ${parseFloat(total/installments).toFixed(2)}`}
                    </p>
                    </div>
                </CheckContainer>
            )}
            {store.brazilian_boleto_pay ? canChooseBoleto ? (
                <CheckContainer
                checked={paymentType === "boleto"}
                onClick={() => setPaymentType("boleto")}
            >
                <div className="column">
                <p>
                    Boleto
                </p>
                <p>
                    R$ {total}
                </p>
                </div>
            </CheckContainer>
            ) : (<CheckContainer
                checked={false}
                disabled={true}
                onClick={() => {}}
            >
                <div className="column">
                <p>
                    Boleto
                </p>
                <p>
                    <i>Você precisa preencher o seu <Link to="/perfil/endereco" >endereço</Link> em seu perfil para utilizar este método de pagamento.</i>
                </p>
                
                </div>
            </CheckContainer>):<></>}
        </Segment>
  );
}

export default CheckoutChoosePayment;